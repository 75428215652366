import { pipe } from "fp-ts/lib/function";
import * as TE from "fp-ts/TaskEither";
import { InternalError } from "../../types/frontend/errros";
import { Vehicle, toWire, toWireArr } from "../../types/frontend/vehicles";
import { urlBasePath } from "../../components/shared/graphsAndIcons/env/vars";

export const UpdateCarsTE = ({
  uuid,
  getToken,
  vehicle,
}: {
  uuid: string;
  getToken: any;
  vehicle: Vehicle;
}): TE.TaskEither<InternalError, unknown> => {
  const url = `${urlBasePath}/user/${uuid}/cars`;
  return pipe(
    TE.tryCatch(
      async () => {
        return fetch(url, {
          headers: {
            Authorization: `${await getToken({ template: "userId_JWT" })}`,
          },
          method: "put",
          body: JSON.stringify({
            vehicle: toWire(vehicle),
            // vehicles: [{ registrationNumber: "AB12CDE" }],
          }),
        });
      },
      (err): any => {
        console.warn("error sending the request:", JSON.stringify(err));
        return "FailedToSend";
      },
    ),

    TE.chain((rsp) => {
      if (rsp.status == 200) {
        return TE.right(undefined);
      }
      return TE.left("UnknownError");
    }),
  );
};
