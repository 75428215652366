import { colourPalette, gradientBackground } from "../shared/colourPalette";

export const customButtonStyles: React.CSSProperties = {
  width: "50vw",
  background: gradientBackground,
  color: colourPalette.lightGreyBackGround,
  fontFamily: "Roboto",
  fontSize: "1.2rem",
  padding: "1rem",
  margin: "1rem",
  border: "none",
  borderRadius: "5rem",
  cursor: "pointer",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
};

export const titleStyle: React.CSSProperties = {
  fontFamily: "Roboto",
  color: colourPalette.primary,
  fontWeight: "bold",
};

export const pageTitleStyle: React.CSSProperties = {
  color: colourPalette.darkGreyBackGround,
  marginTop: "1rem",
  marginBottom: "5rem",
  textAlign: "center",
  fontSize: "2rem",
  fontFamily: "Roboto",
  position: "absolute",
  width: "100%", // Ensure the title spans the entire width of the viewport
};

export const centeredContent: React.CSSProperties = {
  textAlign: "center",
  maxWidth: "100vw",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export const containerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
};

export const titleContainerStyles: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "0.5rem",
};

export const valueStyles: React.CSSProperties = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: colourPalette.darkGreyBackGround,
};

export const descriptionText: React.CSSProperties = {
  fontSize: "0.75rem",
  textAlign: "left",
  color: colourPalette.darkGreyBackGround,
};
export const descriptionStyles: React.CSSProperties = {
  marginTop: "-0.5rem",
  fontSize: "0.75rem",
  color: colourPalette.darkGreyBackGround,
  textAlign: "right",
};
