import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { colourPalette } from "../components/shared/colourPalette";
import { IconsList } from "../components/shared/graphsAndIcons/iconsList";
import { CostOverview } from "./costs/costs";
import { Depreciation } from "./depreciation/depreciation";
import { Maintenance } from "./maintenance.tsx/maintenance";
import { Settings } from "./settings/settings";
import "./shared.css";

import { useAuth, useUser } from "@clerk/clerk-react";
import * as E from "fp-ts/Either";
import { DateTime } from "luxon";
import { useVehicleContext } from "../App";
import { fetchCarsfTE } from "../apis/userCars/fetchCars";
import { fetchUserEvents } from "../apis/vehicleData/maintenence/fetch";
import { CardWithDelete } from "../components/cards/cardWithDelete";
import {
  ReturnButton,
  ReturnStyle,
} from "../components/shared/graphsAndIcons/returnButton";
import { Events } from "../types/frontend/context";
import { Vehicle } from "../types/frontend/vehicles";
import { CarList } from "./cars/carsList";

const presentNotifications = ({
  userEvents,
  registrationNumber,
  setSelectedPage,
  setUserEvents,
}: {
  userEvents: Events;
  registrationNumber: string;
  setSelectedPage: Dispatch<SetStateAction<PageIcons>>;
  setUserEvents: Dispatch<SetStateAction<Events>>;
}) => {
  if (userEvents[registrationNumber]) {
    const taxEvent = userEvents[registrationNumber].Tax;
    const MOTEvent = userEvents[registrationNumber].MOT;

    const onCloseTax = () => {
      setUserEvents({
        ...userEvents,
        [registrationNumber]: {
          ...userEvents[registrationNumber],
          Tax: { ...taxEvent, display: false },
        },
      });
      // navigate to maintenance page
    };
    const onCloseMOT = () => {
      setUserEvents({
        ...userEvents,
        [registrationNumber]: {
          ...userEvents[registrationNumber],
          MOT: { ...MOTEvent, display: false },
        },
      });
      // navigate to maintenance page
    };
    const onClickTax = () => {
      onCloseTax();
      setSelectedPage("Cost Overview");
    };
    const onClickMOT = () => {
      onCloseMOT();
      setSelectedPage("Maintenance");
    };

    const taxNotification =
      taxEvent.date < DateTime.now().plus({ days: 30 }) && taxEvent.display ? (
        <CardWithDelete
          title="Tax Due Soon"
          text={`We expect that your road tax will be  £${taxEvent.baseCost} for ${registrationNumber}.`}
          value={`£${taxEvent.baseCost}`}
          valueSubTitle={taxEvent.date.toFormat("dd-MM-yyyy")}
          onClick={onClickMOT}
          onDelete={onCloseMOT}
          deleteEnabled={true}
        />
      ) : null;

    const MOTNotification =
      MOTEvent.date < DateTime.now().plus({ days: 30 }) && MOTEvent.display ? (
        <CardWithDelete
          title="MOT Due Soon"
          text={`We anticipate additional maintenence will be required on your vehicle, click here to find out more.`}
          value={`£${MOTEvent.baseCost}+`}
          valueSubTitle={MOTEvent.date.toFormat("dd-MM-yyyy")}
          onClick={onClickMOT}
          onDelete={onCloseMOT}
          deleteEnabled={true}
        />
      ) : null;

    return (
      <>
        {taxNotification}
        {MOTNotification}
      </>
    );
  } else {
    return null;
  }
};

export type PageIcons =
  | "Cost Overview"
  | "Depreciation"
  | "Maintenance"
  | "Usage";

const containerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
};

const centeredContent: React.CSSProperties = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const CarCarouselItem = ({
  vehicleReg,
  selectedIconPage,
  setSelectedIconPage,
}: {
  vehicleReg: string;
  selectedIconPage: PageIcons;
  setSelectedIconPage: Dispatch<SetStateAction<PageIcons>>;
}) => {
  const { vehicles } = useVehicleContext();

  const [userEvents, setUserEvents] = useState<Events>({});
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(
    undefined
  );

  const { user } = useUser();
  const { getToken } = useAuth();

  useEffect(() => {
    if (vehicles.length === 0) {
      // Check if vehicles is initially empty
      fetchCarsfTE({ uuid: user?.id ?? "", getToken })().then(
        E.fold(
          (x) => {}, // TODO return the actual error here,
          (data) => {
            setSelectedVehicle(
              data.find((v) => v.registrationNumber === vehicleReg)
            );
            if (selectedVehicle) {
              setUserEvents(fetchUserEvents({ vehicles: [selectedVehicle] }));
            }
          }
        )
      );
    } else {
      setSelectedVehicle(
        vehicles.find((v) => v.registrationNumber === vehicleReg)
      );
      if (selectedVehicle) {
        setUserEvents(fetchUserEvents({ vehicles: [selectedVehicle] }));
      }
    }
  }, []);

  let icon: React.ReactNode;

  if (!selectedVehicle) {
    return <h1>not found </h1>;
  }

  switch (selectedIconPage) {
    case "Cost Overview":
      icon = (
        <>
          {presentNotifications({
            userEvents,
            registrationNumber: selectedVehicle.registrationNumber,
            setSelectedPage: setSelectedIconPage,
            setUserEvents,
          })}
          <CostOverview vehicle={selectedVehicle} />
        </>
      );
      break;
    case "Depreciation":
      icon = (
        <>
          <div
            style={{
              width: "100vw",
              padding: "1rem",
            }}
          >
            <Depreciation vehicle={selectedVehicle} />
          </div>
        </>
      );

      break;
    case "Maintenance":
      icon = <Maintenance vehicle={selectedVehicle} />;
      break;
    case "Usage":
      icon = <Settings vehicle={selectedVehicle} />;
      break;
    default:
      icon = null;
  }

  return (
    <div style={containerStyles}>
      <div style={centeredContent}>
        {/* <IconsList
          selectedPage={selecteIconPage}
          setSelectedPage={setSelectedIconPage}
        /> */}
        <div
          style={{
            paddingBottom: "5rem",
          }}
        >
          {icon}
        </div>
      </div>
    </div>
  );
};

export const Cars = ({
  vehicles,
  carPage,
  setCarPage,
  selectedIconPage,
  setSelectedIconPage,
}: {
  vehicles: Array<Vehicle>;
  carPage: string;
  setCarPage: (x: string) => void;
  selectedIconPage: PageIcons;
  setSelectedIconPage: Dispatch<SetStateAction<PageIcons>>;
}) => {
  let selectedPage: React.ReactNode;

  switch (carPage) {
    case "Cars":
      selectedPage = (
        <div style={{ ...centeredContent, marginTop: "10rem" }}>
          <CarList vehicles={vehicles} handleSelect={setCarPage} />
        </div>
      );
      break;

    default:
      selectedPage = (
        <>
          <div style={{ ...centeredContent, marginTop: "10rem" }}>
            <ReturnStyle onClick={() => setCarPage("Cars")} />
            <CarCarouselItem
              vehicleReg={carPage}
              selectedIconPage={selectedIconPage}
              setSelectedIconPage={setSelectedIconPage}
            />
          </div>
        </>
      );
  }
  useEffect(() => undefined, [carPage]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        padding: "1rem",
      }}
    >
      {selectedPage}
    </div>
  );
};
