import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { HomePageCard } from "../components/cards/landingPageCard";
import { SimplifiedCarList } from "../components/homePageVehicles/carsList";
import { colourPalette } from "../components/shared/colourPalette";
import { GenericPieChart } from "../components/shared/graphsAndIcons/pieChart";
import { Vehicle } from "../types/frontend/vehicles";
import { calculateVehicleCosts } from "./costs/calculations";
import { InternalTab } from "./tabBar/tabBar";

export const Home = ({
  vehicles,
  selectVehicle,
  setActiveTab,
}: {
  vehicles: Array<Vehicle>;
  selectVehicle: (x: string) => void;
  setActiveTab: (x: InternalTab) => void;
}) => {
  const [vehicleCosts, setVehicleCosts] = useState<
    Array<{ name: string; value: any }>
  >([]);

  const [totalCost, setTotalCost] = useState<number>(NaN);

  useEffect(() => {
    const vehicleCosts = vehicles.map((x) => {
      const { totalCost } = calculateVehicleCosts({
        vehicle: x,
      });
      return {
        name: x.registrationNumber,
        value: parseFloat(totalCost.toFixed(2)),
      };
    });
    setVehicleCosts(vehicleCosts);
    setTotalCost(
      vehicleCosts.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      )
    );
  }, [vehicles]);

  const { lightGreyBackGround } = colourPalette; // Assuming colourPalette is defined elsewhere

  return (
    <>
      <Card
        style={{
          height: vehicles.length > 0 ? "30vh" : "0vh",
          border: "none",
          marginTop: "4rem",
          backgroundColor: lightGreyBackGround,
        }}
      >
        <GenericPieChart
          data={vehicleCosts}
          labelText={DateTime.now().toFormat("yyyy")}
          labelValue={totalCost}
        />
      </Card>
      <div
        style={{
          marginBottom: "10rem",
          width: "100%",
          height: "50vh",
        }}
      >
        <HomePageCard
          title="Upcoming costs"
          onClick={() => {
            setActiveTab("Costs");
          }}
        />
        <SimplifiedCarList
          vehicles={vehicles}
          handleSelect={(x) => selectVehicle(x)}
        />
      </div>
    </>
  );
};
