import React from "react";
import { Button } from "react-bootstrap";
import {
  BarChartFill,
  CpuFill,
  GraphDownArrow,
  Icon,
  PieChartFill
} from "react-bootstrap-icons";
import { useNavigate } from "react-router";
import { colourPalette } from "../../../components/shared/colourPalette";
import {
  customButtonStyles,
  titleStyle,
} from "../../../components/styles/generalStyles";
import { pages } from "../../../routing";
import logoImage from "../logo.png";
import { ApologyBanner } from "./apologyBanner";

export const AlignTextAndIcon = ({
  text,
  Icon,
  colour,
}: {
  text: string;
  Icon: Icon;
  colour?: string;
}) => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "1rem",
        fontWeight: "bold",
        fontSize: "1.2rem",
        fontFamily: "Roboto",
        color: colour ?? colourPalette.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{ fontSize: "1.2em", marginRight: "10px" }}>
        <Icon />
      </span>
      <span
        style={{
          fontSize: "1.5rem",
        }}
      >
        {text}
      </span>
    </div>
  );
};

export const MobileLandingPage: React.FC = () => {
  const navigate = useNavigate();

  const subtitleStyle: React.CSSProperties = {
    fontFamily: "Roboto",
    color: colourPalette.darkGreyBackGround,
    fontSize: "1.5rem",
    textAlign: "center",
    marginTop: "1rem",
  };

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "white",
    padding: "2rem",
  };

  const logoStyle: React.CSSProperties = {
    width: "70%",
    maxWidth: "200px",
    marginBottom: "2rem",
  };

  const skipTextStyle: React.CSSProperties = {
    textDecoration: "none",
    fontWeight: "bold",
    cursor: "pointer",
    color: colourPalette.darkGreyBackGround,
  };

  const benefitsStyle: React.CSSProperties = {
    textAlign: "center",
    marginTop: "1rem",
    fontWeight: "bold",
    fontSize: "1.2rem",
    fontFamily: "Roboto",
    color: colourPalette.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={containerStyle}>
      <img src={logoImage} alt="Logo" style={logoStyle} />
      <div style={{ justifyContent: "center", alignItems: "center" }}>
        <ApologyBanner/>
        <h1 style={{ ...titleStyle, fontSize: "3rem", textAlign: "center" }}>
          
          Welcome to Kinekt
        </h1>
        <h2 style={subtitleStyle}>{`Simplified Car Cost Management`}</h2>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ ...customButtonStyles, fontWeight: "bold" }}
            onClick={() => navigate(pages.login)}
          >
            LOGIN
          </Button>
        </div>
        <div
          style={{
            ...benefitsStyle,
            fontWeight: "normal",
            color: colourPalette.darkGreyBackGround,
          }}
        >
          {"with a free account"}
        </div>
    
        <AlignTextAndIcon
          text={"AI Powered MOT Cost Predictions"}
          Icon={() => <CpuFill />}
        />
        <AlignTextAndIcon
          text={"Depreciation Forecast"}
          Icon={() => <GraphDownArrow />}
        />
        <AlignTextAndIcon
          text={"Monthly Cost Predictions"}
          Icon={() => <BarChartFill />}
        />

        <div
          style={{
            ...benefitsStyle,
            fontWeight: "normal",
            color: colourPalette.darkGreyBackGround,
          }}
        >
          {"or"}
        </div>
        <AlignTextAndIcon
          text={"Annual Cost Predictions"}
          Icon={() => <PieChartFill />}
          colour={colourPalette.secondary}
        />
        <div
          style={{
            textAlign: "center",
            marginTop: "1rem",
            color: colourPalette.darkGreyBackGround,
          }}
        >
          <a onClick={() => navigate(pages.trial)} style={skipTextStyle}>
            {"Continue Without Login >"}
          </a>
        </div>
      </div>
    </div>
  );
};
