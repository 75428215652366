import { useAuth, useUser } from "@clerk/clerk-react";
import * as E from "fp-ts/Either";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UpdateCarsTE } from "../../apis/userCars/updateCars";
import { colourPalette } from "../../components/shared/colourPalette";
import { MultiSelect } from "../../components/shared/graphsAndIcons/multiSelect";
import { MyRangeSliderWithRange } from "../../components/shared/graphsAndIcons/slider";
import { customButtonStyles } from "../../components/styles/generalStyles";
import { FrequencyOfJourneys, Vehicle } from "../../types/frontend/vehicles";

export const Settings = ({ vehicle }: { vehicle: Vehicle }) => {
  const { user } = useUser();
  const { getToken } = useAuth();

  const [updateVehicle, setUpdateVehicle] = useState<Vehicle>(vehicle);
  const handleRefresh = () => {
    window.location.reload();
  };
  const saveUpdates = (vehicleWithUpdates: Vehicle) => {
    UpdateCarsTE({
      uuid: user?.id ?? "",
      getToken,
      vehicle: vehicleWithUpdates,
    })().then(E.map(() => handleRefresh()));
  };

  const subTitleStyles: React.CSSProperties = {
    color: colourPalette.primary,
    marginTop: "1rem",
    textAlign: "left",
    fontSize: "1.2rem",
    fontFamily: "Roboto",
  };

  const [mileage, setMileage] = useState<number>(
    vehicle.usage.userDefinedAnnualMileage,
  );

  const [age, setAge] = useState<number>(vehicle.usage.primaryUserAge);
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

  const [zone1Resident, setZone1Resident] = useState<number>(
    vehicle.usage.zone1resident ? 1 : 0,
  );

  const keyMappingFromText: Record<
    FrequencyOfJourneys,
    {
      key: FrequencyOfJourneys;
      value: number;
    }
  > = {
    Never: { key: "Never", value: 0 },
    Monthly: { key: "Monthly", value: 0.25 },
    Fortnightly: { key: "Fortnightly", value: 0.5 },
    Weekly: { key: "Weekly", value: 1 },
    "Every other day": { key: "Every other day", value: 3 },
    Daily: { key: "Daily", value: 5 },
  };

  const [ulezWeeklyEntries, setUlezWeeklyEntries] = useState<{
    key: FrequencyOfJourneys;
    value: number;
  }>(keyMappingFromText[vehicle.usage.ulezJourneys]);

  const [congestionChargeWeeklyEntries, setCongestionChargeWeeklyEntries] =
    useState<{
      key: FrequencyOfJourneys;
      value: number;
    }>(keyMappingFromText[vehicle.usage.congestionChargeJourneys]);

  useEffect(() => {
    setUpdateVehicle({
      ...updateVehicle,
      usage: {
        zone1resident: zone1Resident == 1,
        primaryUserAge: age,
        userDefinedAnnualMileage: mileage,
        ulezJourneys: ulezWeeklyEntries.key,
        congestionChargeJourneys: congestionChargeWeeklyEntries.key,
        updatedAt: DateTime.now().toISO(),
      },
    });
    setSaveEnabled(false);
    if (age != vehicle.usage.primaryUserAge) {
      setSaveEnabled(true);
    }
    const x = zone1Resident ? true : false;
    if (x != vehicle.usage.zone1resident) {
      setSaveEnabled(true);
    }
    if (ulezWeeklyEntries.key != vehicle.usage.ulezJourneys) {
      setSaveEnabled(true);
    }

    if (mileage != vehicle.usage.userDefinedAnnualMileage) {
      setSaveEnabled(true);
    }
    if (
      congestionChargeWeeklyEntries.key !=
      vehicle.usage.congestionChargeJourneys
    ) {
      setSaveEnabled(true);
    }
  }, [
    age,
    zone1Resident,
    mileage,
    ulezWeeklyEntries,
    congestionChargeWeeklyEntries,
  ]);

  const keyMappingFromNumber: Record<
    number,
    {
      key: FrequencyOfJourneys;
      value: number;
    }
  > = {
    "0": { key: "Never", value: 0 },
    "0.25": { key: "Monthly", value: 0.25 },
    "0.5": { key: "Fortnightly", value: 0.5 },
    "1": { key: "Weekly", value: 1 },
    "3": { key: "Every other day", value: 3 },
    "5": { key: "Daily", value: 5 },
  };

  return (
    <div
    style={{
      padding: "1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
      {saveEnabled ? (
        <Button
          style={{ ...customButtonStyles, width: "80vw" }}
          onClick={() => saveUpdates(updateVehicle)}
        >
          Update
        </Button>
      ) : null}
      {/* <h1 style={subTitleStyles}> {"Personal Information"}</h1> */}
      {/* <MultiSelect
        title={"MOT and Tax Reminders"}
        inputArr={[
          { key: "Yes", value: 0 },
          { key: "No", value: 1 },
        ]}
        currentValue={notifcations ?? 0}
        setValue={setNotifications}
      /> */}

      {/* <h1 style={subTitleStyles}> {"Vehicle Usage"}</h1> */}

      <MultiSelect
        title={"Zone 1 Resident:"}
        inputArr={[
          { key: "Yes", value: 1 },
          { key: "No", value: 0 },
        ]}
        currentValue={zone1Resident ?? 0}
        setValue={setZone1Resident}
        infoCircle="Zone 1 Residents are eligible for a 90% reduction in congestion charges."
      />

      <MyRangeSliderWithRange
        title={`Primary Driver Age: ${age}`}
        min={17}
        max={75}
        step={10}
        initialValue={age}
        onValueChange={setAge}
        roundTo={1}
        infoCircle={
          "Primary driver age is one of the most prominent factor in insurance costs."
        }
      />
      <MyRangeSliderWithRange
        title={`Annual Mileage:  ${mileage}`}
        min={0}
        max={25000}
        step={5000}
        initialValue={mileage}
        onValueChange={setMileage}
        roundTo={100}
        lastYearValue={vehicle.calculatedVehicleCosts.lastAnnualVehicleMilage}
      />

      <MultiSelect
        title={"ULEZ Journeys"}
        inputArr={[
          { key: "Never", value: 0 },
          { key: "Monthly", value: 0.25 },
          { key: "Fortnightly", value: 0.5 },
          { key: "Weekly", value: 1 },
          { key: "Every other day", value: 3 },
          { key: "Daily", value: 5 },
        ]}
        setValue={(value) => setUlezWeeklyEntries(keyMappingFromNumber[value])}
        currentValue={ulezWeeklyEntries.value ?? 0}
      />
      <div style={{ paddingBottom: "1rem" }}>
        <MultiSelect
          title={"Congestion Charge Journeys"}
          inputArr={[
            { key: "Never", value: 0 },
            { key: "Monthly", value: 0.25 },
            { key: "Fortnightly", value: 0.5 },
            { key: "Weekly", value: 1 },
            { key: "Every other day", value: 3 },
            { key: "Daily", value: 5 },
          ]}
          setValue={(value) =>
            setCongestionChargeWeeklyEntries(keyMappingFromNumber[value])
          }
          currentValue={congestionChargeWeeklyEntries.value ?? 0}
        />
      </div>
      </div>

  );
};
