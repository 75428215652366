import { calculateAnnualDepreciation } from "../../components/shared/annualDepreciation";
import {
  calculateMaintenanceCostMultiplier,
  getOccurrencesPerWeek,
  interpolateCost,
} from "../../components/shared/calculations";
import { Vehicle } from "../../types/frontend/vehicles";

const mapKeysToSentences = (expenseValues: any): Record<string, string> => {
  const sentenceMap: Record<string, string> = {
    depreciation: "Depreciation",
    insurance: "Insurance",
    maintenance: "Maintenance",
    fuel: "Fuel",
    parking: "Parking",
    MOT: "MOT",
    roadTax: "Road tax",
    annualCongestionCharge: "Annual Congestion Charge",
    annualULEZ: "Annual ULEZ",
  };

  const result: Record<string, string> = {};

  Object.keys(expenseValues).forEach((key) => {
    const sentence = sentenceMap[key];
    const value = expenseValues[key];
    result[key] = `${sentence}: £${value.toFixed(0)}`;
  });

  return result;
};

export const calculateVehicleCosts = ({ vehicle }: { vehicle: Vehicle }) => {
  const annualMileage =
    vehicle.usage.userDefinedAnnualMileage > 0
      ? vehicle.usage.userDefinedAnnualMileage
      : vehicle.calculatedVehicleCosts.lastAnnualVehicleMilage;

  const depreciation = calculateAnnualDepreciation(vehicle);
  const zone1ResidentMultiplier = vehicle.usage.zone1resident ? 0.1 : 1;
  const congestionChargeWeeklyCost =
    getOccurrencesPerWeek(vehicle.usage.congestionChargeJourneys) * 15;
  const annualCongestionCharge =
    congestionChargeWeeklyCost * 52 * zone1ResidentMultiplier;

  const ulezBinary = vehicle.calculatedVehicleCosts.ulezRequired ? 1 : 0;
  const annualULEZCharge =
    getOccurrencesPerWeek(vehicle.usage.ulezJourneys) * ulezBinary * 12.5 * 52;

  const maintenance =
    (calculateMaintenanceCostMultiplier(
      vehicle.calculatedVehicleCosts.vehicleMilage,
    ) *
      annualMileage) /
    25000;

  const costs = {
    depreciation: parseFloat(depreciation.toFixed(2)),
    insurance: parseFloat(
      interpolateCost(vehicle.usage.primaryUserAge).toFixed(2),
    ),
    maintenance: parseFloat(maintenance.toFixed(2)),
    fuel: parseFloat(
      (
        vehicle.calculatedVehicleCosts.fuelCostPerMile *
        vehicle.usage.userDefinedAnnualMileage
      ).toFixed(2),
    ),
    parking: parseFloat(vehicle.calculatedVehicleCosts.parking.toFixed(2)),
    MOT: parseFloat((50.0).toFixed(2)),
    roadTax: parseFloat(vehicle.calculatedVehicleCosts.roadTax.toFixed(2)),
    annualCongestionCharge: parseFloat(annualCongestionCharge.toFixed(2)),
    annualULEZ: parseFloat(annualULEZCharge.toFixed(2)),
  };

  return {
    totalCost: Object.values(costs).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    ),
    displayCosts: Object.entries(mapKeysToSentences(costs)).map((x) => ({
      name: x[0],
      value: x[1],
    })),
    costs: Object.entries({
      Depreciation: depreciation,
      Insurance: interpolateCost(vehicle.usage.primaryUserAge),
      "Maintenance and MOT": maintenance + 50,
      fuel:
        vehicle.calculatedVehicleCosts.fuelCostPerMile *
        vehicle.usage.userDefinedAnnualMileage,
      Other:
        vehicle.calculatedVehicleCosts.parking +
        vehicle.calculatedVehicleCosts.roadTax +
        annualCongestionCharge +
        annualULEZCharge,
    }).map((x) => ({
      name: x[0],
      value: parseInt(x[1].toFixed(0)),
    })),
  };
};
