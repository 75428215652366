import { constNull, constVoid } from "fp-ts/lib/function";
import React, { useState } from "react";
import { Trash3Fill } from "react-bootstrap-icons";
import { colourPalette } from "../../components/shared/colourPalette";
import { InfoCircleData } from "../shared/graphsAndIcons/infoCircle";
import {
  descriptionStyles,
  descriptionText,
  titleContainerStyles,
  titleStyle,
  valueStyles,
} from "../styles/generalStyles";

interface CardProps {
  title: string;
  value: string;
  text: string;
  infoCircle?: string;
  onClick?: () => void;
  onDelete?: () => void;
  valueSubTitle: string;
  element?: () => JSX.Element;
  deleteEnabled?: Boolean;
  shadow?: Boolean;
  moreInfo?: Boolean;
}

export const CardWithDelete: React.FC<CardProps> = ({
  title,
  value,
  text,
  infoCircle,
  onClick = constVoid,
  onDelete = constVoid,
  valueSubTitle,
  element = constNull,
  deleteEnabled = true,
  shadow = true,
  moreInfo = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  //Wrapper function around setIsHovered, prevents the state from changing if
  // the delete is disabled
  const deleteEnabledCheck = (x: boolean) => {
    if (deleteEnabled) {
      setIsHovered(x);
    }
  };

  const onConfirmedClick = (hoverActiveOnClick: boolean) => {
    if (!deleteEnabled) {
      onClick();
    } else {
      if (hoverActiveOnClick) {
        onClick();
      }
    }
  };
  const cardStyles: React.CSSProperties = {
    padding: "1rem",
    borderRadius: "1rem",
    boxShadow: shadow ? "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1)" : "none",
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    overflow: "hidden",
    transition: "0.6s ease",
  };

  const deleteIconStyles: React.CSSProperties = {
    display: "flex", // Center vertically
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
    color: "white",
    fontWeight: "bold",
    fontSize: "2rem", // Increase size
    pointerEvents: isHovered ? "auto" : "none",
    marginLeft: "-0.5rem",
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    width: "20%",
    height: "100%",
    position: "absolute",
    visibility: isHovered ? "visible" : "hidden",
    opacity: isHovered ? 1 : 0,
    left: isHovered ? "80%" : "-50%",
    backgroundColor: isHovered ? colourPalette.reject : "transparent",
    transition: `left 0.6s ease, opacity 0.6s ease ${
      isHovered ? "0.3s" : "0s"
    }, visibility 0s linear ${isHovered ? "0.1s" : "0s"}`,
    zIndex: 1,
  };
  const infoCircleData = infoCircle ? (
    <InfoCircleData text={infoCircle} />
  ) : null;

  const moreInfoText = moreInfo ? (
    <span
      onClick={() => onClick()}
      style={{
        color: colourPalette.primary,
        cursor: "pointer",
        fontWeight: "bold",
        fontFamily: "Roboto",
      }}
    >
      More Information &gt;
    </span>
  ) : null;

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <div
        style={{
          ...cardStyles,
          flex: "1",
          transition: "margin-right 0.6s ease",
          marginRight: isHovered ? "20%" : "0",
          zIndex: 2,
        }}
        onMouseLeave={() => {
          // Delay the card to allow for the delete to occur
          setTimeout(() => {
            deleteEnabledCheck(false);
          }, 500);
        }}
        onClick={() => {
          deleteEnabledCheck(true);
          setTimeout(() => {
            deleteEnabledCheck(false);
          }, 4000);
          onConfirmedClick(isHovered);
        }}
      >
        <div style={{ ...titleContainerStyles }}>
          <div
            style={{ ...titleStyle, fontSize: "1.5rem" }}
            onClick={() => onClick()}
          >
            {title}
          </div>
          <div style={valueStyles}>
            {value}
            {infoCircleData}
          </div>
        </div>
        <div style={descriptionStyles}>{valueSubTitle}</div>
        <div style={descriptionText}>
          <div style={{ width: "80%" }}>{text}</div>
          {element()}
          {moreInfoText}
        </div>
      </div>
      <div
        style={deleteIconStyles}
        onClick={() => {
          return onDelete();
        }}
      >
        <Trash3Fill />
      </div>
    </div>
  );
};
