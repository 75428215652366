import { useState } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { CardWithDelete } from "../../components/cards/cardWithDelete";
import { customButtonStyles } from "../../components/styles/generalStyles";
import { Vehicle } from "../../types/frontend/vehicles";
import { AddVehileModal } from "../Modals/addVehicle";
import { useAuth } from "@clerk/clerk-react";
import { colourPalette } from "../shared/colourPalette";
import { capitalizeFirstWord } from "../shared/standardisations";

export const SimplifiedCarList = ({
  vehicles,
  handleSelect,
}: {
  vehicles: Array<Vehicle>;
  handleSelect: (x: string) => void;
}) => {
  const [showModal, setShowModal] = useState(vehicles.length == 0);
  const { signOut } = useAuth();

  return (
    <div
      style={{
        paddingBottom: "5rem",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AddVehileModal showModal={showModal} setShowModal={setShowModal} />

      {vehicles.map((x, index) => {
        const valueText = `£${x.calculatedVehicleCosts.estimatedCurrentValue.toFixed(
          2
        )}`;
        return (
          <>
            <CardWithDelete
              key={index}
              title={x.registrationNumber}
              value={valueText}
              text={`${capitalizeFirstWord(
                x.vehicleDetails.colour ?? ""
              )}, ${capitalizeFirstWord(x.make)} ${capitalizeFirstWord(
                x.model
              )}`}
              valueSubTitle={"Estimated Value"}
              onClick={() => handleSelect(x.registrationNumber)}
              deleteEnabled={false}
              moreInfo={true}
            />
          </>
        );
      })}
      <Button
        style={{
          ...customButtonStyles,
          width: "100%",
          padding: "none",
        }}
        onClick={() => setShowModal(true)}
      >
        <Plus
          style={{
            width: "100%",
            height: "3rem",
          }}
        />
      </Button>
      <div
        style={{
          width: "100vw",
          padding: "none",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          background: colourPalette.lightGreyBackGround,
        }}
      >
        <Button
          style={{
            ...customButtonStyles,
            background: colourPalette.mediumGrey,
            marginTop: "5rem",
            padding: "none",
          }}
          onClick={() => signOut()}
        >
          Sign Out
        </Button>
      </div>
    </div>
  );
};
