import { DateTime } from "luxon";
import { Data, Layout } from "plotly.js";
import React, { useContext, useEffect } from "react";
import Plot from "react-plotly.js";
import { RelationshipType, Vehicle } from "../../../types/frontend/vehicles";

function splitArrayInHalf(arr: Array<any>) {
  const middleIndex = Math.floor((arr.length + 1) / 2) + 1;
  const firstHalf = arr.slice(0, middleIndex);
  const secondHalf = arr.slice(middleIndex - 1);

  return [firstHalf, secondHalf];
}

export const ExampleChart = ({
  inputdata,
}: {
  inputdata: Array<{ year: DateTime; value: number }>;
}) => {
  // useEffect(() =>   , [gloabalIndex]);
  const currentDate = new Date();
  const dates = inputdata.map((x) => x.year);
  const costs = inputdata.map((x) => x.value);

  const today = currentDate.toISOString().split("T")[0];

  const twoPointsDates = [
    dates[0].toString(),
    dates[dates.length - 1].toString(),
  ];
  const twoPointsCosts = [costs[0], costs[costs.length - 1]];

  const [historicdates, futuredates] = splitArrayInHalf(dates);
  const [historiccosts, futurecosts] = splitArrayInHalf(costs);

  const data: Data[] = [
    {
      x: historicdates.map((x) => x.toString()),
      y: historiccosts,
      mode: "lines",
      line: { shape: "spline", color: "#6e4ce5", width: 3 }, // Smooth the line, set color, make it thicker
      name: "Cost",
      hoverinfo: "skip", // Remove text on hover
    },
    {
      x: twoPointsDates,
      y: twoPointsCosts.map((x) => x.toFixed()),
      mode: "markers",
      marker: { color: "#6e4ce5", size: 8 },
      // hoverinfo: 'skip', // Remove text on hover
    },
    {
      x: futuredates.map((x) => x.toString()),
      y: futurecosts,
      mode: "lines",
      line: { shape: "spline", color: "#6e4ce5", width: 3, dash: "dash" }, // Smooth the line, set color, make it thicker
      name: "Cost",
      hoverinfo: "skip", // Remove text on hover
    },
  ];

  const range = Math.max(...costs) - Math.min(...costs);

  // Round to the nearest 100
  const layout: Partial<Layout> = {
    margin: {
      l: 20, // Set the left margin to 0
      r: 60, // Adjust the right margin as needed
      t: 50, // Adjust the top margin as needed
      b: 50, // Adjust the bottom margin as needed
    },
    xaxis: {
      type: "date",
      // tickvals: xaxisValues,
      // ticktext: xaxisText,
      showline: false, // Hide x-axis line
    },

    shapes: [
      {
        type: "line",
        x0: today,
        y0: 0,
        x1: today,
        yref: "paper",
        y1: 1,
        line: {
          color: "grey",
          width: 1.5,
          dash: "dot",
        },
      },
    ],
    yaxis: {
      type: "linear",
      side: "right", // Move the y-axis to the right
      tickprefix: "£",
      gridcolor: "rgba(128, 128, 128, 0.3)", // Set the color of the gridlines
      showgrid: true, //
      range: [0, historiccosts[0] * 1.1],
    },
    font: {
      family: "Arial, sans-serif", // Specify the desired font family
      size: 14, // Specify the font size
      color: "#333", // Specify the font color
    },
    plot_bgcolor: "rgba(0,0,0,0)", // Transparent plot background
    paper_bgcolor: "rgba(0,0,0,0)", // Transparent paper background
    showlegend: false,
  };

  const containerStyle: React.CSSProperties = {
    width: "100vw",
    maxWidth: "100%", // Ensure the maximum width is also 100%
    height: "40vh",
    maxHeight: "100%",
    overflowY: "hidden",
    overflowX: "visible",
  };

  return (
    <Plot
      data={data}
      layout={layout}
      config={{ responsive: true, staticPlot: true }}
      style={containerStyle}
    />
  );
};

export interface MileageData {
  year: DateTime;
  mileage: number;
  age: number;
  isPetrol: boolean;
}

export function generateMileageTimeSeries({
  annualMileageNow,
  isPetrol,
  vehicle,
}: {
  annualMileageNow: number;
  isPetrol: boolean;
  vehicle: Vehicle;
}): MileageData[] {
  // Validate input
  // if (
  //   !firstRegistration.isValid ||
  //   firstRegistration > DateTime.now() ||
  //   lastMOTMileage < 0
  // ) {
  //   console.error(
  //     "Invalid input. Age must be a Luxon DateTime object, and current mileage must be non-negative."
  //   );
  //   return undefined;
  // }

  // Initialize an array to store the time series data
  const timeSeries: MileageData[] = [];
  const now = DateTime.now();
  // Calculate mileage increment per year
  const yearsDiff = vehicle.calculatedVehicleCosts.vehicleAge;
  const mileage = vehicle.calculatedVehicleCosts.vehicleMilage;

  // Generate time series data

  for (let year = yearsDiff; year >= 0; year--) {
    const yearDate = now.minus({ years: year });

    timeSeries.push({
      year: yearDate,
      mileage,
      age: vehicle.calculatedVehicleCosts.vehicleAge - year,
      isPetrol,
    });
  }

  timeSeries.push({
    year: now,
    mileage: vehicle.calculatedVehicleCosts.vehicleMilage,
    age: vehicle.calculatedVehicleCosts.vehicleAge,
    isPetrol,
  });

  for (let year = 0; year <= 10; year++) {
    const mileageIncrease = Math.round(annualMileageNow * year);
    const yearDate = now.plus({ years: year });
    timeSeries.push({
      year: yearDate,
      mileage: vehicle.calculatedVehicleCosts.vehicleMilage + mileageIncrease,
      age: vehicle.calculatedVehicleCosts.vehicleAge + year,
      isPetrol,
    });
  }

  return timeSeries;
}

function processRelationship(
  type: RelationshipType,
  coefficient: number,
  value: number,
): number {
  switch (type) {
    case "constant":
      return coefficient;
    case "linear":
      return coefficient * value;
    case "logarithmic":
      return coefficient * Math.log(value);
    case "quadratic":
      return coefficient * value * value;
    default:
      console.warn("Unexpected Relationship type provided for cost equation");
      return 0;
  }
}

type VehicleValueEquation = {
  age_Coefficient: number;
  ageRelationship: RelationshipType;
  miles_Coefficient: number;
  milageRelationship: RelationshipType;
  fuel_Petrol_Coefficient: number;
  fuelRelationship: RelationshipType;
  intercept: number;
  updatedAt: string;
};

export const estimateVehicleValueEquation = ({
  data: { mileage, age, isPetrol },
  equation: {
    age_Coefficient,
    ageRelationship,
    miles_Coefficient,
    milageRelationship,
    fuel_Petrol_Coefficient,
    fuelRelationship,
    intercept,
  },
}: {
  data: {
    mileage: number;
    age: number;
    isPetrol: boolean;
  };
  equation: VehicleValueEquation;
}) => {
  const isPetrolLog = isPetrol ? 0 : 1; // map to this in the backedn, complete hack

  return Math.max(
    intercept +
      processRelationship(ageRelationship, age_Coefficient, age) +
      processRelationship(milageRelationship, miles_Coefficient, mileage) +
      processRelationship(
        fuelRelationship,
        fuel_Petrol_Coefficient,
        isPetrolLog,
      ),
    0,
  );
};
