import * as t from "io-ts";

const MotFailureMetaDataItemC = t.type({
  code: t.string,
  text: t.string,
  cost: t.number,
  likelihood: t.number,
});

export const MotFailureMetaDataC = t.type({
  "1.2.1": MotFailureMetaDataItemC,
  "1.4.2": MotFailureMetaDataItemC,
  "2.1.3": MotFailureMetaDataItemC,
  "4.1.1": MotFailureMetaDataItemC,
  "4.1.2": MotFailureMetaDataItemC,
  "4.2.1": MotFailureMetaDataItemC,
  "4.3.1": MotFailureMetaDataItemC,
  "4.7.1": MotFailureMetaDataItemC,
  "5.3.2": MotFailureMetaDataItemC,
  "5.3.4": MotFailureMetaDataItemC,
  "6.1.7": MotFailureMetaDataItemC,
  "8.2.1.2": MotFailureMetaDataItemC,
});

export type MotFailureMetaDataC = t.TypeOf<typeof MotFailureMetaDataC>;
