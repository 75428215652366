import { colourPalette, gradientBackground } from "../colourPalette";
import { InfoCircleData } from "./infoCircle";

export const MultiSelect = ({
  title,
  inputArr,
  setValue,
  currentValue,
  infoCircle,
}: {
  title: string;
  inputArr: Array<{ key: string; value: number }>;
  setValue: (x: number) => void;
  currentValue: number;
  infoCircle?: string;
}) => {
  const textColor = "#ecf0f1";
  const infoCircleData = infoCircle ? (
    <InfoCircleData text={infoCircle} />
  ) : null;

  return (
    <div
      style={{
        marginTop: "1rem",
        width: "100%",
        position: "relative",
        fontFamily: "Roboto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "2rem",
        }}
      >
        <h1
          style={{
            color: colourPalette.darkGreyBackGround,
            textAlign: "left",
            fontSize: "1rem",
            fontFamily: "Roboto",
          }}
        >
          {title}
        </h1>
        {infoCircleData}
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {inputArr.map(({ key, value }) => (
          <div
            key={value}
            style={{
              cursor: "pointer",
              width: "6rem",
              height: "3rem",
              margin: "0.2rem",
              padding: "0.4rem",
              background:
                value === currentValue
                  ? gradientBackground
                  : colourPalette.lightGreyBackGround,
              color:
                value === currentValue
                  ? textColor
                  : colourPalette.darkGreyBackGround,
              border: "1px solid #ddd",
              borderRadius: "8px",
              fontSize: "0.8rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "Roboto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              fontWeight: "bold", // Make text slightly more bold
            }}
            onClick={() => {
              setValue(value);
            }}
          >
            {key}
          </div>
        ))}
      </div>
    </div>
  );
};
