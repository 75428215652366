import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import { InternalError } from "../../../types/frontend/errros";
import { VehicleAPIInfo } from "../../../types/frontend/vehicleAPI";
import { VehicleAPIInfoC } from "../../../types/wire/vehicleAPi";
import { urlBasePath } from "../../../components/shared/graphsAndIcons/env/vars";

export const fetchByVehicleInfoTE = ({
  vehicleReg,
  getToken,
}: {
  vehicleReg: string;
  getToken: any;
}): TE.TaskEither<InternalError, VehicleAPIInfo> => {
  const url = `${urlBasePath}/vehicle/${vehicleReg}`;

  return pipe(
    TE.tryCatch(
      async () => {
        return fetch(url, {
          headers: {
            Authorization: `${await getToken({ template: "userId_JWT" })}`,
          },
          method: "get",
        }).then((response) => response.json());
      },
      (err): InternalError => {
        console.warn("Error sending the request:", JSON.stringify(err));
        return "FailedToSend";
      },
    ),

    TE.chain((jsondata) => {
      return pipe(
        TE.tryCatch(
          async () => JSON.parse(jsondata.data),
          (err): InternalError => {
            return "NotFound";
          },
        ),

        TE.chain((r) =>
          pipe(
            r,
            VehicleAPIInfoC.decode,
            E.mapLeft((): InternalError => "UnknownError"),
            TE.fromEither,
          ),
        ),
      );
    }),
  );
};
