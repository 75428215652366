import * as t from "io-ts";

// Define io-ts types for enums
const RelationshipType = t.keyof({
  constant: null,
  linear: null,
  logarithmic: null,
  quadratic: null,
});

const frequencyOfJourneys = t.keyof({
  Never: null,
  Monthly: null,
  Fortnightly: null,
  Weekly: null,
  "Every other day": null,
  Daily: null,
});
const RfrAndCommentsType = t.type({
  text: t.string,
  type: t.string,
  dangerous: t.boolean,
});

const MotTest = t.type({
  completedDate: t.string,
  testResult: t.string,
  odometerValue: t.string,
  odometerUnit: t.union([t.string, t.undefined]),
  motTestNumber: t.string,
  odometerResultType: t.string,
  rfrAndComments: t.array(RfrAndCommentsType),
});

const VehicleValueEquation = t.type({
  age_Coefficient: t.number,
  ageRelationship: RelationshipType,
  miles_Coefficient: t.number,
  milageRelationship: RelationshipType,
  fuel_Petrol_Coefficient: t.number,
  fuelRelationship: RelationshipType,
  intercept: t.number,
  updatedAt: t.string,
});

const CalculatedVehicleCosts = t.type({
  vehicleValueEquation: t.array(VehicleValueEquation),
  estimatedCurrentValue: t.number,
  vehicleAge: t.number,
  vehicleMilage: t.number,
  lastAnnualVehicleMilage: t.number,
  fuelCostPerMile: t.number,
  ulezRequired: t.boolean,
  parking: t.number,
  MOT: t.number,
  roadTax: t.number,
});

const Usage = t.type({
  zone1resident: t.boolean,
  primaryUserAge: t.number,
  userDefinedAnnualMileage: t.number,
  ulezJourneys: frequencyOfJourneys,
  congestionChargeJourneys: frequencyOfJourneys,
  updatedAt: t.string,
});

// Define the main VehicleV2 io-ts type
export const VehicleWireC = t.type({
  registrationNumber: t.string,
  make: t.string,
  model: t.string,
  vehicleDetails: t.partial({
    monthOfFirstRegistration: t.union([t.undefined, t.string]),
    yearOfManufacture: t.union([t.undefined, t.number]),
    motStatus: t.union([t.undefined, t.string]),
    motExpiryDate: t.union([t.undefined, t.string]),
    co2EmissionsPerKGperKM: t.union([t.undefined, t.number]),
    fuelType: t.union([t.undefined, t.string]),
    colour: t.union([t.undefined, t.string]),
    taxDueDate: t.union([t.undefined, t.string]),
    taxStatus: t.union([t.undefined, t.string]),
  }),
  MOTHistory: t.array(MotTest),
  calculatedVehicleCosts: CalculatedVehicleCosts,
  usage: Usage,
});

export type VehicleWireC = t.TypeOf<typeof VehicleWireC>;

export const VehicleArrWireC = t.array(VehicleWireC);
export type VehicleArrWireC = t.TypeOf<typeof VehicleArrWireC>;
