import { Vehicle } from "../../types/frontend/vehicles";
import { estimateVehicleValueEquation } from "./graphsAndIcons/valueForecast";

export const calculateAnnualDepreciation = (vehicle: Vehicle): number => {
  const annualMileage =
    vehicle.usage.userDefinedAnnualMileage > 0
      ? vehicle.usage.userDefinedAnnualMileage
      : vehicle.calculatedVehicleCosts.lastAnnualVehicleMilage;

  const depreciation =
    estimateVehicleValueEquation({
      data: {
        mileage: vehicle.calculatedVehicleCosts.vehicleMilage,
        age: vehicle.calculatedVehicleCosts.vehicleAge,
        isPetrol: vehicle.vehicleDetails.fuelType == "Petrol",
      },
      equation: vehicle.calculatedVehicleCosts.vehicleValueEquation[0],
    }) -
    estimateVehicleValueEquation({
      data: {
        mileage: vehicle.calculatedVehicleCosts.vehicleMilage + annualMileage,
        age: vehicle.calculatedVehicleCosts.vehicleAge + 1,
        isPetrol: vehicle.vehicleDetails.fuelType == "Petrol",
      },
      equation: vehicle.calculatedVehicleCosts.vehicleValueEquation[0],
    });
  return depreciation;
};
