import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { colourPalette } from "../colourPalette";

export const InfoCircleData = ({ text }: { text: string }) => (
  <OverlayTrigger
    placement="left" // Adjust placement to "left"
    overlay={
      <Tooltip
        id="tooltip"
        style={{
          backgroundColor: colourPalette.lightGreyBackGround,
          color: "purple",
          fontFamily: "Roboto",
        }}
      >
        {text}
      </Tooltip>
    }
  >
    <InfoCircle
      style={{
        marginBottom: ".5rem",
        marginLeft: "1rem",
        width: "1rem",
        transition: "color 0.3s ease",
        cursor: "pointer",
      }}
    />
  </OverlayTrigger>
);
