import { ArrowLeft } from "react-bootstrap-icons";
import { colourPalette } from "../colourPalette";

export const ReturnButton = () => (
  <a
    href="javascript:history.go(-1)"
    style={{
      position: "fixed",
      top: "1rem",
      left: "1rem",
      zIndex: 10,
      color: colourPalette.darkGreyBackGround,
    }}
  >
    <ArrowLeft size={30} />
  </a>
);

export const ReturnStyle = ({ onClick }: { onClick: any }) => (
  <a
    onClick={onClick}
    style={{
      position: "fixed",
      top: "1rem",
      left: "1rem",
      zIndex: 10,
      color: colourPalette.darkGreyBackGround,
    }}
  >
    <ArrowLeft size={30} />
  </a>
);
