import * as E from "fp-ts/Either";
import * as TE from "fp-ts/TaskEither";
import { pipe } from "fp-ts/lib/function";
import { InternalError } from "../../types/frontend/errros";
import { Vehicle, fromWireArr } from "../../types/frontend/vehicles";
import { VehicleArrWireC } from "../../types/wire/vehicles";
import { urlBasePath } from "../../components/shared/graphsAndIcons/env/vars";

export const fetchCarsfTE = ({
  uuid,
  getToken,
}: {
  uuid: string;
  getToken: any;
}): TE.TaskEither<InternalError, Array<Vehicle>> => {
  const url = `${urlBasePath}/user/${uuid}/cars`;

  return pipe(
    TE.tryCatch(
      async () => {
        return fetch(url, {
          headers: {
            Authorization: `${await getToken({ template: "userId_JWT" })}`,
          },
          method: "get",
        }).then((response) => response.json());
      },
      (err): any => {
        console.warn("error sending the request:", JSON.stringify(err));
        return "FailedToSend";
      },
    ),
    TE.chain((rsp) =>
      pipe(
        JSON.parse(rsp.data),
        VehicleArrWireC.decode,
        E.bimap(
          (err): InternalError => "UnknownError",
          (data): Array<Vehicle> => fromWireArr(data),
        ),
        TE.fromEither,
      ),
    ),
  );
};
