import React from "react";
import { Card } from "react-bootstrap";
import { CashCoin, Gear, GraphDownArrow, Tools } from "react-bootstrap-icons";
import { PageIcons } from "../../../pages/cars";
import { colourPalette, gradientBackground } from "../colourPalette";

const IconCard = ({
  page,
  selected,
  setSelectedPage,
}: {
  page: PageIcons;
  selected: boolean;
  setSelectedPage: React.Dispatch<React.SetStateAction<PageIcons>>;
}) => {
  if (selected) {
  }

  const textColor = "#ecf0f1";

  const cardStyle = {
    width: "5rem",
    height: "5rem",
    margin: "0.5rem",
    padding: "0.8rem 0.4rem 1.4rem 0.4rem",
    background: selected
      ? gradientBackground
      : colourPalette.lightGreyBackGround,
    color: selected ? textColor : colourPalette.darkGreyBackGround,
  };

  const iconStyle = {
    width: "100%",
    height: "100%",
    color: selected ? textColor : colourPalette.darkGreyBackGround,
  };

  const textStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    textAlign: "center",
    fontSize: "0.6rem",
    padding: "0.2rem 0.1rem 0.2rem 0.1rem",
    color: selected ? textColor : colourPalette.darkGreyBackGround,
  };

  return (
    <Card
      className="card shadow-sm"
      style={cardStyle}
      onClick={() => setSelectedPage(page)}
    >
      {page == "Cost Overview" ? (
        <CashCoin style={iconStyle} />
      ) : page == "Depreciation" ? (
        <GraphDownArrow style={iconStyle} />
      ) : page == "Maintenance" ? (
        <Tools style={iconStyle} />
      ) : (
        <Gear style={iconStyle} />
      )}
      <div style={textStyle}>{page}</div>
    </Card>
  );
};

export const IconsList = ({
  selectedPage,
  setSelectedPage,
}: {
  selectedPage: PageIcons;
  setSelectedPage: React.Dispatch<React.SetStateAction<PageIcons>>;
}) => {
  const containerStyle = {
    display: "flex",
    marginTop: "1rem",
    background: colourPalette.lightGreyBackGround
  };
  const pages: Array<PageIcons> = [
    "Cost Overview",
    "Depreciation",
    "Maintenance",
    "Usage",
  ];
  return (
    <div style={containerStyle}>
      {pages.map((page, index) => (
        <IconCard
          key={index}
          page={page}
          selected={selectedPage == page}
          setSelectedPage={setSelectedPage}
        />
      ))}
    </div>
  );
};
