import React from "react";
import logoImage from "../logo.png";
import { colourPalette } from "../../../components/shared/colourPalette";
import { titleStyle } from "../../../components/styles/generalStyles";

export const WebLandingPage: React.FC = () => {
  const subtitleStyle: React.CSSProperties = {
    fontFamily: "Roboto",
    color: colourPalette.darkGreyBackGround,
    fontSize: "2rem",
    textAlign: "left", // Align left
  };

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100vh",
    backgroundColor: "white", // Plain white background
    position: "relative", // Make container relative for absolute positioning of logo
  };

  const logoStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%", // Position in the middle vertically
    right: "-10%", // Adjust distance from right side
    transform: "translateY(-30%)", // Center vertically
    minWidth: "70vw",
    height: "auto",
    opacity: 0.5, // Apply opacity to fade the logo
    zIndex: 1, // Ensure logo is behind text
  };

  const contentContainerStyle: React.CSSProperties = {
    flex: 1,
    display: "flex",
    minWidth: "100vw",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
    paddingLeft: "10%", // Adjust spacing
    position: "relative", // Make container relative for stacking text on top
    zIndex: 2, // Ensure text is on top of the logo
  };
  const boldLinkStyle: React.CSSProperties = {
    fontWeight: "bold",
    fontStyle: "italic",
  };

  return (
    <div style={containerStyle}>
      <div style={contentContainerStyle}>
        <h1 style={{ ...titleStyle, fontSize: "4rem", textAlign: "left" }}>
          Kinekt
        </h1>
        <h2 style={subtitleStyle}>
          {`Currently we are only available on mobile, please visit:`}
          <br />
          <br />
          <strong style={boldLinkStyle}>www.kinekt.uk</strong>
          <br />
          <br />
          {`on your phone to get started.`}
        </h2>
      </div>
      <img src={logoImage} alt="Logo" style={logoStyle} />
    </div>
  );
};
