import { DateTime } from "luxon";
import {
  Costs,
  Events,
  MOTEvent,
  TaxEvent,
} from "../../../types/frontend/context";
import { Vehicle } from "../../../types/frontend/vehicles";

export const fetchUserEvents = ({
  vehicles,
}: {
  vehicles: Array<Vehicle>;
}): Events => {
  return vehicles.reduce<Events>((result, vehicle) => {
    const registrationNumber = vehicle.registrationNumber;

    // Calculate MOT costs
    const motInfo: MOTEvent = {
      baseCost: vehicle.calculatedVehicleCosts.MOT,
      additionalCostsAverage: 272,
      additionalCostLower: 125,
      additionalCostsUpper: 350,
      date: DateTime.fromISO(vehicle.vehicleDetails.motExpiryDate || ""),
      display: true, // Adjust as needed
    };

    // Calculate Tax costs
    const taxInfo: TaxEvent = {
      baseCost: vehicle.calculatedVehicleCosts.roadTax,
      date: DateTime.fromISO(vehicle.vehicleDetails.taxDueDate || ""),
      display: true, // Adjust as needed
    };

    result[registrationNumber] = { MOT: motInfo, Tax: taxInfo };

    return result;
  }, {});
};
