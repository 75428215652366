import { useAuth } from "@clerk/clerk-react";
import * as E from "fp-ts/Either";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { fetchMOTLikeliHoodsTE } from "../../apis/motLikelihoods/fetch";
import { CardWithDelete } from "../../components/cards/cardWithDelete";
import { colourPalette } from "../../components/shared/colourPalette";
import { customButtonStyles } from "../../components/styles/generalStyles";
import { Vehicle } from "../../types/frontend/vehicles";
import { MOTandTax } from "../costs/costs";

const tableStyles: React.CSSProperties = {
  width: "100%",
  marginRight: "1rem",
  maxHeight: "10rem",
  overflowY: "auto",
  borderCollapse: "collapse",
  marginTop: "1rem",
};

const thStyles: React.CSSProperties = {
  borderBottom: "2px solid #ddd",
  padding: "0.5rem",
  textAlign: "left",
  fontSize: "0.7rem", // Reduced font size
  color: colourPalette.primary, // Set the color to primary from the colourPalette
};

const tdStyles: React.CSSProperties = {
  borderBottom: "1px solid #ddd",
  textOverflow: "clip",
  padding: "0.5rem",
  fontSize: "0.8rem",
  textAlign: "left",
};

interface RequiredMaintenanceCardProps {
  title: string;
  subTitle: string;
  date: string;
  value: string;
  tableData: Array<{
    failure: string;
    cost: string;
    likelihood: string;
  }>;
}

export const Maintenance = ({ vehicle }: { vehicle: Vehicle }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [motCard, setMOTCard] = useState<RequiredMaintenanceCardProps>({
    title: "MOT",
    date: vehicle.vehicleDetails.motExpiryDate ?? "MOT Expiry not found",
    subTitle: ``,
    value: ``,
    tableData: [],
  });

  const { getToken } = useAuth();

  const now = DateTime.now();
  useEffect(() => {
    fetchMOTLikeliHoodsTE({
      ageDays: vehicle.calculatedVehicleCosts.vehicleAge * 365,
      mileage: vehicle.calculatedVehicleCosts.vehicleMilage,
      getToken,
    })().then(
      E.fold(
        (x) => {
          setLoading(false);
          setMOTCard({
            title: "MOT",
            date:
              vehicle.vehicleDetails.motExpiryDate ?? "MOT Expiry not found",
            subTitle: `Error calculating MOT data.`,
            value: ``,
            tableData: [],
          });
        },
        (data) => {
          const sortedTableData = Object.values(data)
            .filter((x) => x.likelihood > 0.005)
            .sort((a, b) => b.likelihood - a.likelihood);

          const totalMaintenenceCost = parseFloat(
            sortedTableData
              .reduce((sum, element) => {
                return sum + element.cost * element.likelihood;
              }, 0)
              .toFixed(2),
          );

          const motCard: RequiredMaintenanceCardProps = {
            title: "MOT",
            date: vehicle.vehicleDetails.motExpiryDate ?? "unknown",
            subTitle: `Based on vehicles of a similar age and mileage, we have forecast the averge cost of maintence for this vehicle to be £${totalMaintenenceCost}, and expect the MOT itself to cost £50.`,
            value: `£${(totalMaintenenceCost + 50).toFixed(2)}`,
            tableData: sortedTableData.map((x) => ({
              failure: x.text,
              cost: `£${x.cost}`,
              likelihood: `${(x.likelihood * 100).toFixed(1)}%`,
            })),
          };
          setLoading(false);

          return setMOTCard(motCard);
        },
      ),
    );
  }, []);

  const subTitleStyles: React.CSSProperties = {
    color: colourPalette.darkGreyBackGround,
    marginTop: "1rem",
    textAlign: "left",
    fontSize: "1.2rem",
    fontFamily: "Roboto",
  };

  const MOTDue = DateTime.fromISO(vehicle.vehicleDetails.motExpiryDate ?? "");

  return (
    <div
      style={{
        padding: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <MOTandTax
        n={MOTDue.diffNow("days").days}
        type="MOT"
        expired={DateTime.now() > MOTDue}
        dueDate={MOTDue}
      />

      <h1 style={subTitleStyles}> {"Required Maintenance"}</h1>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh", // Optional: Set a specific height for the container
          }}
        >
          <Spinner />
        </div>
      ) : (
        [motCard].map((card, index) => {
          const table: () => JSX.Element =
            card.tableData.length > 0
              ? () => (
                  <table style={tableStyles}>
                    <thead>
                      <tr>
                        <th style={{ ...thStyles, width: "40%" }}>
                          Potential Problem
                        </th>
                        <th style={thStyles}>Cost</th>
                        <th style={thStyles}>Likelihood</th>
                      </tr>
                    </thead>
                    <tbody>
                      {card.tableData.map((row, index) => (
                        <tr key={index}>
                          <td style={tdStyles}>{row.failure}</td>
                          <td style={tdStyles}>{row.cost}</td>
                          <td style={tdStyles}>{row.likelihood}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              : (): JSX.Element => <></>;
          return (
            <CardWithDelete
              key={index}
              title={card.title}
              value={card.value}
              text={card.subTitle}
              valueSubTitle={card.date}
              infoCircle={
                "We use Machine Learning to forcast the most likely MOT failures based on your cars age and mileage."
              }
              element={table}
              deleteEnabled={false}
            />
          );
        })
      )}

      <Button style={{ ...customButtonStyles, width: "80vw" }} disabled={true}>
        Book Maintenence - Coming Soon
      </Button>
    </div>
  );
};
