import { RedirectToSignIn } from "@clerk/clerk-react";
import { Navigate, RouteObject } from "react-router-dom";
import "./App.css";
import { LoggedOutHome } from "./pages/landing";
import { LoggedInHome } from "./pages/loggedInhome";
import { TrialPage } from "./pages/notLoggedIn/landingPage";

type Pages = "home" | "trial" | "login" | "cars" | "car" | "costs";

type Paths =
  | "/"
  | "/*"
  | ""
  | "/home"
  | "/trial"
  | "/Login"
  | "/cars"
  | "/car/:vehicleReg"
  | "/costs"
  | "*";

export const pages: Record<Pages, Paths> = {
  home: "/",
  trial: "/trial",
  login: "/Login",
  cars: "/cars",
  car: "/car/:vehicleReg",
  costs: "/costs",
};

export const routes: Array<RouteObject & { path: Paths }> = [
  {
    path: "/",
    element: <LoggedOutHome />,
  },
  {
    path: "/Login",
    element: <RedirectToSignIn />,
  },
  {
    path: "/trial",
    element: <TrialPage />,
  },

  {
    path: "*",
    element: <LoggedOutHome />,
  },
];

export const loggedInroutes: Array<RouteObject & { path: Paths }> = [
  {
    path: "/Login",
    element: <Navigate to="/" replace={true} />,
  },
  {
    path: "*",
    element: <LoggedInHome />,
  },
];
