import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { loggedInroutes, routes } from "./routing";

// Import the functions you need from the SDKs you need
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { colourPalette } from "./components/shared/colourPalette";
import { internalLogging } from "./components/shared/graphsAndIcons/env/localhostLogging";
import { Vehicle } from "./types/frontend/vehicles";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const urlB64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const publicKey =
  "BBNNZSs5JEQWQ5TMMpk9HJY33mX8rF7hIBXIhnl8XTO4kcZjou1B1kORfKp6n5GZ5Jz_y4AyDSmuVdX8YbycMKc";
const applicationServerKey = urlB64ToUint8Array(publicKey);

interface VehicleContextType {
  vehicles: Array<Vehicle>;
  setVehicles: Dispatch<SetStateAction<Array<Vehicle>>>;
}

// Create a context to manage vehicle
const VehicleContext = createContext<VehicleContextType | undefined>(undefined);

// Custom hook to access vehicle data
export const useVehicleContext = () => {
  const context = useContext(VehicleContext);
  if (!context) {
    throw new Error("useVehicleContext must be used within a VehicleProvider");
  }
  return context;
};

function App() {
  const content = useRoutes(routes);
  const loggedInContent = useRoutes(loggedInroutes);
  const [vehicles, setVehicles] = useState<Array<Vehicle>>([]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(`${process.env.PUBLIhomeC_URL}/service-worker.js`)
        .then((registration) => {
          internalLogging(
            "Service Worker registered with scope:",
            registration.scope,
          );

          registration.pushManager
            .subscribe({ userVisibleOnly: true, applicationServerKey })
            .then(
              (pushSubscription) => {
                internalLogging("endpoint", JSON.stringify(pushSubscription));
                // The push subscription details needed by the application
                // server are now available, and can be sent to it using,
                // for example, the fetch() API.
              },
              (error) => {
                internalLogging(
                  "unexpected error subscribing the registration.pushManager",
                  error,
                );
              },
            );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  const frontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;
  if (!frontendApi) {
    throw Error("Unable to find frontend API");
  }
  let apiProdDev = frontendApi;

  if (!window.location.hostname.includes("kinekt.uk")) {
    apiProdDev = "clerk.grand.osprey-28.lcl.dev";
  }

  const navigate = useNavigate();

  return (
    <>
      <ClerkProvider frontendApi={apiProdDev!} navigate={(to) => navigate(to)}>
        <SignedIn>
          <VehicleContext.Provider value={{ vehicles, setVehicles }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100%",
                backgroundColor: colourPalette.lightGreyBackGround,
              }}
            >
              {loggedInContent}
            </div>
          </VehicleContext.Provider>
        </SignedIn>
        <SignedOut>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              minHeight:"100vh",
              backgroundColor: colourPalette.lightGreyBackGround,
            }}
          >
            {content}
          </div>
        </SignedOut>
      </ClerkProvider>
    </>
  );
}

export default App;
