import { Button, Modal } from "react-bootstrap";
import { CostsArr } from "../../types/frontend/costs";
import { colourPalette, gradientBackground } from "../shared/colourPalette";
import { useState } from "react";
import { customButtonStyles } from "../styles/generalStyles";

export const SelectButton = ({
  inputArr,
  setSelectedValues,
  selectedValues,
  width = "30%",
  height = "2rem",
}: {
  inputArr: Array<string>;
  setSelectedValues: (x: any) => void;
  selectedValues: Array<string>;
  width?: string;
  height?: string;
}) => {
  const excludedList: CostsArr = inputArr.includes("Insurance")
    ? ["Insurance", "Service"]
    : [];
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          style={{
            borderBottom: "none",
            marginBottom: "0rem",
            paddingBottom: "0rem",
          }}
          closeButton
        >
          <Modal.Title>Coming Soon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Insuance and Service cost calculations coming soon.
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none", padding: "0px" }}>
          <Button
            style={{ ...customButtonStyles, width: "100vw" }}
            onClick={() => {
              handleClose();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          position: "relative",
          fontFamily: "Roboto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {[
            ...inputArr.filter(
              // remove those from the excluded list hack
              (x) =>
                !x.includes(excludedList[0]) && !x.includes(excludedList[1]),
            ),
          ].map((value, i) => (
            <div
              key={i}
              style={{
                cursor: "pointer",
                minWidth: "6rem",
                width,
                height,
                margin: "0.2rem",
                padding: "0.4rem",
                background: selectedValues.includes(value)
                  ? gradientBackground
                  : colourPalette.lightGreyBackGround,
                color: selectedValues.includes(value)
                  ? colourPalette.lightGreyBackGround
                  : colourPalette.darkGreyBackGround,
                border: "1px solid #ddd",
                borderRadius: "2rem",
                fontSize: "0.8rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "Roboto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center", // Center text vertically
                fontWeight: "bold", // Make text slightly more bold
              }}
              onClick={() => {
                if (selectedValues.includes(value)) {
                  setSelectedValues([
                    ...selectedValues.filter((x) => x != value),
                  ]);
                } else {
                  setSelectedValues([...selectedValues, value]);
                }
              }}
            >
              {value}
            </div>
          ))}
          {[...excludedList].map((value, i) => (
            <div
              key={i}
              style={{
                cursor: "pointer",
                minWidth: "6rem",
                width,
                height,
                margin: "0.2rem",
                padding: "0.4rem",
                background: colourPalette.lightGreyBackGround,
                color: colourPalette.darkGreyBackGround,
                border: "1px solid #ddd",
                borderRadius: "2rem",
                fontSize: "0.8rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "Roboto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center", // Center text vertically
                fontWeight: "bold", // Make text slightly more bold
              }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
