import { DateTime } from "luxon";
import React from "react";
import { Button } from "react-bootstrap";
import { CardWithDelete } from "../../components/cards/cardWithDelete";
import { colourPalette } from "../../components/shared/colourPalette";
import { customButtonStyles } from "../../components/styles/generalStyles";
import { MOTandTax } from "../costs/costs";

const tableStyles: React.CSSProperties = {
  width: "100%",
  marginRight: "1rem",
  maxHeight: "10rem",
  overflowY: "auto",
  borderCollapse: "collapse",
  marginTop: "1rem",
};

const thStyles: React.CSSProperties = {
  borderBottom: "2px solid #ddd",
  padding: "0.5rem",
  textAlign: "left",
  fontSize: "0.7rem", // Reduced font size
  color: colourPalette.primary, // Set the color to primary from the colourPalette
};

const tdStyles: React.CSSProperties = {
  borderBottom: "1px solid #ddd",

  textOverflow: "clip",
  padding: "0.5rem",
  fontSize: "0.8rem",
  textAlign: "left",
};

interface RequiredMaintenanceCardProps {
  title: string;
  subTitle: string;
  date: string;
  value: string;
  tableData: Array<{
    failure: string;
    cost: string;
    likelihood: string;
  }>;
}

export const MaintenanceMock = () => {
  const mockData = {
    "1.2.1": {
      code: "1.2.1",
      text: "Service Brake Performance",
      cost: 100,
      likelihood: 0,
    },
    "1.4.2": {
      code: "1.4.2",
      text: "Parking Brake Efficiency",
      cost: 200,
      likelihood: 0,
    },
    "2.1.3": {
      code: "2.1.3",
      text: "Steering linkage condition",
      cost: 100,
      likelihood: 0.02,
    },
    "4.1.1": {
      code: "4.1.1",
      text: "Headlamp Failure",
      cost: 20,
      likelihood: 0,
    },
    "4.1.2": {
      code: "4.1.2",
      text: "Headlamp Alignment",
      cost: 10,
      likelihood: 0.0025,
    },
    "4.2.1": {
      code: "4.2.1",
      text: "Lamp Failure",
      cost: 20,
      likelihood: 0,
    },
    "4.3.1": {
      code: "4.3.1",
      text: "Stop Lamp Failure",
      cost: 20,
      likelihood: 0,
    },
    "4.7.1": {
      code: "4.7.1",
      text: "Registration Lamp Failure",
      cost: 20,
      likelihood: 0,
    },
    "5.3.2": {
      code: "5.3.2",
      text: "Shock absorbers",
      cost: 200,
      likelihood: 0.04533333333333333,
    },
    "5.3.4": {
      code: "5.3.4",
      text: "Suspension joints, pins and bushes",
      cost: 150,
      likelihood: 0,
    },
    "6.1.7": {
      code: "6.1.7",
      text: "Const Velocity Joint Replacement",
      cost: 200,
      likelihood: 0.13,
    },
    "8.2.1.2": {
      code: "8.2.1.2",
      text: "Gaseous emissions",
      cost: 135,
      likelihood: 0,
    },
  };

  const sortedTableData = Object.values(mockData)
    .filter((x) => x.likelihood > 0.005)
    .sort((a, b) => b.likelihood - a.likelihood);

  const totalMaintenenceCost = parseFloat(
    sortedTableData
      .reduce((sum, element) => {
        return sum + element.cost * element.likelihood;
      }, 0)
      .toFixed(2),
  );
  const motCard: RequiredMaintenanceCardProps = {
    title: "MOT",
    date: "15-04-2023",
    subTitle: `Based on vehicles of a similar age and mileage, we have forecast the averge cost of maintence for this vehicle to be £${totalMaintenenceCost}, and expect the MOT itself to cost £50.`,
    value: `£${(totalMaintenenceCost + 50).toFixed(2)}`,
    tableData: sortedTableData.map((x) => ({
      failure: x.text,
      cost: `£${x.cost}`,
      likelihood: `${(x.likelihood * 100).toFixed(1)}%`,
    })),
  };

  const subTitleStyles: React.CSSProperties = {
    color: colourPalette.darkGreyBackGround,
    marginTop: "1rem",
    textAlign: "left", // Align text to the center
    fontSize: "1.2rem", // Adjust the font size as needed
    fontFamily: "Roboto", // Replace 'Your-Modern-Font' with the desired modern font
  };
  const MOTDueMock = DateTime.now().plus({ days: 25 });

  return (
    <div
      style={{
        marginTop: "2rem",
      }}
    >
      <MOTandTax
        n={MOTDueMock.diffNow("days").days}
        type="MOT"
        expired={DateTime.now() > MOTDueMock}
        dueDate={MOTDueMock}
      />
      <h1 style={subTitleStyles}> {"Required Maintenance"}</h1>
      {[motCard].map((card, index) => {
        const table: () => JSX.Element =
          card.tableData.length > 0
            ? () => (
                <table style={tableStyles}>
                  <thead>
                    <tr>
                      <th style={{ ...thStyles, width: "40%" }}>
                        Potential Problem
                      </th>
                      <th style={thStyles}>Cost</th>
                      <th style={thStyles}>Likelihood</th>
                    </tr>
                  </thead>
                  <tbody>
                    {card.tableData.map((row, index) => (
                      <tr key={index}>
                        <td style={tdStyles}>{row.failure}</td>
                        <td style={tdStyles}>{row.cost}</td>
                        <td style={tdStyles}>{row.likelihood}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            : (): JSX.Element => <></>;
        return (
          <CardWithDelete
            key={index}
            title={card.title}
            value={card.value}
            text={card.subTitle}
            valueSubTitle={card.date}
            infoCircle={
              "We use Machine Learning to forcast the most likely MOT failures based on your cars age and mileage."
            }
            element={table}
            deleteEnabled={false}
          />
        );
      })}

      <Button style={{ ...customButtonStyles, width: "80vw" }} disabled={true}>
        Book Maintenence - Coming Soon
      </Button>
    </div>
  );
};
