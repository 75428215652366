import { VehicleArrWireC, VehicleWireC } from "../wire/vehicles";

export type VehicleReg = {
  registrationNumber: string;
};

type RfrAndComments = {
  text: string;
  type: string;
  dangerous: boolean;
};

type MotTest = {
  completedDate: string;
  testResult: string;
  // expiryDate: string;
  odometerValue: string;
  odometerUnit: string | undefined;
  motTestNumber: string;
  odometerResultType: string;
  rfrAndComments: RfrAndComments[];
};

export type RelationshipType =
  | "constant"
  | "linear"
  | "logarithmic"
  | "quadratic";

export type FrequencyOfJourneys =
  | "Never"
  | "Monthly"
  | "Fortnightly"
  | "Weekly"
  | "Every other day"
  | "Daily";

type Usage = {
  zone1resident: boolean;
  primaryUserAge: number;
  userDefinedAnnualMileage: number;
  ulezJourneys: FrequencyOfJourneys;
  congestionChargeJourneys: FrequencyOfJourneys;
  updatedAt: string;
};

type VehicleValueEquation = {
  make?: string;
  model?: string;
  age_Coefficient: number;
  ageRelationship: RelationshipType;
  miles_Coefficient: number;
  milageRelationship: RelationshipType;
  fuel_Petrol_Coefficient: number;
  fuelRelationship: RelationshipType;
  intercept: number;
  updatedAt: string;
};

type VehicleCosts = {
  fuelCostPerMile: number;
  ulezRequired: boolean;
  parking: number;
  MOT: number;
  roadTax: number;
};

type CalculatedVehicleInfo = {
  estimatedCurrentValue: number;
  vehicleAge: number;
  vehicleMilage: number;
  lastAnnualVehicleMilage: number;
};

export type Vehicle = {
  registrationNumber: string;
  make: string;
  model: string;
  vehicleDetails: {
    monthOfFirstRegistration?: string;
    yearOfManufacture?: number;
    motStatus?: string;
    motExpiryDate?: string;
    co2EmissionsPerKGperKM?: number;
    fuelType?: string;
    colour?: string;
    taxDueDate?: string;
    taxStatus?: string;
  };
  MOTHistory: Array<MotTest>;
  calculatedVehicleCosts: CalculatedVehicleInfo &
    VehicleCosts & {
      vehicleValueEquation: Array<VehicleValueEquation>;
    };
  usage: Usage;
};

export const fromWire = (vehicle: VehicleWireC): Vehicle => {
  return vehicle;
};

export const toWire = (vehicle: Vehicle): VehicleWireC => {
  return vehicle;
};

export const fromWireArr = (vehicles: VehicleArrWireC): Array<Vehicle> => {
  return vehicles;
};

export const toWireArr = (vehicles: Array<Vehicle>): VehicleArrWireC => {
  return vehicles;
};
