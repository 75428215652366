import * as t from "io-ts";

const EventTypeWire = t.union([
  t.literal("Tax"),
  t.literal("MOT"),
  t.literal("OilChange"),
  t.literal("Parking"),
  t.literal("Insurance"),
  t.literal("TyreChange"),
]);

// Define the Event type
const EventWire = t.intersection([
  t.type({
    vehicleReg: t.string,
    type: EventTypeWire,
    dueDate: t.string,
    baseCost: t.number,
  }),
  t.partial({ additionalCost: t.number }),
]);

// Define the Events type as an array of Event
export const UpcomingCostEventsWire = t.array(EventWire);

// Export the types
export type UpcomingCostEventsWire = t.TypeOf<typeof UpcomingCostEventsWire>;
