import { UpcomingCostEvents } from "../../types/frontend/events";
import { FrequencyOfJourneys, Vehicle } from "../../types/frontend/vehicles";
import { DateTime } from "luxon";
import { calculateAnnualDepreciation } from "./annualDepreciation";
import { Record } from "react-bootstrap-icons";
import { CostsArr } from "../../types/frontend/costs";

const getOccurrencesPerMonth = (frequency: FrequencyOfJourneys): number => {
  switch (frequency) {
    case "Never":
      return 0;
    case "Monthly":
      return 1;
    case "Fortnightly":
      return 2;
    case "Weekly":
      return 4.3;
    case "Every other day":
      return 11;
    case "Daily":
      return 22;
    default:
      console.error(
        "[getOccurrencesPerMonth] Invalid frequency provided:",
        frequency,
      );
      return 0;
  }
};

const generateDateArray = (): DateTime[] => {
  const currentDate = DateTime.local();
  const dateArray: DateTime[] = [];

  for (let i = -1; i <= 6; i++) {
    const newDate = currentDate.plus({ months: i });
    dateArray.push(newDate.startOf("month"));
  }

  return dateArray;
};

const baseMonthlyCosts = ({
  vehicle,
  selectedCosts,
}: {
  vehicle: Vehicle;
  selectedCosts: CostsArr;
}): number => {
  let total = 0;
  if (selectedCosts.includes("Fuel")) {
    const monthlyFuelCost =
      (vehicle.usage.userDefinedAnnualMileage / 12) *
      vehicle.calculatedVehicleCosts.fuelCostPerMile;
    total += monthlyFuelCost;
  }
  if (selectedCosts.includes("Parking")) {
    const monthlyParkingCost = vehicle.calculatedVehicleCosts.parking / 12;
    total += monthlyParkingCost;
  }
  if (selectedCosts.includes("Congestion Charge")) {
    const zone1residentMultiplier = vehicle.usage.zone1resident ? 0.1 : 1;
    const monthlyCongestionCharge =
      getOccurrencesPerMonth(vehicle.usage.congestionChargeJourneys) *
      zone1residentMultiplier;
    total += monthlyCongestionCharge;
  }
  if (selectedCosts.includes("ULEZ")) {
    const ulezMultiplier = vehicle.calculatedVehicleCosts.ulezRequired ? 1 : 0;
    const monthlyUlezJourneys =
      getOccurrencesPerMonth(vehicle.usage.ulezJourneys) * ulezMultiplier;
    total += monthlyUlezJourneys;
  }
  if (selectedCosts.includes("Depreciation")) {
    const monthlyDepreciation = calculateAnnualDepreciation(vehicle) / 12;

    total += monthlyDepreciation;
  }
  return total;
};

const groupEventsByDateTimeKey = (
  events: UpcomingCostEvents,
  baseCosts: number,
  dateArray: DateTime[],
  selectedCosts: CostsArr,
): Record<string, { totalCost: number; events: UpcomingCostEvents }> => {
  return dateArray.reduce(
    (
      acc: Record<string, { totalCost: number; events: UpcomingCostEvents }>,
      date,
    ) => {
      const key = date.toISODate();
      const eventsForDate = events.filter(
        (event) =>
          event.dueDate.month === date.month &&
          event.dueDate.year === date.year,
      );
      const eventsCosts = eventsForDate.reduce((eventCostacc, val) => {
        const addtionalCost = val.additionalCost ?? 0;
        return val.baseCost + addtionalCost;
      }, 0);

      acc[key] = { totalCost: baseCosts + eventsCosts, events: eventsForDate };
      return acc;
    },
    {},
  );
};

const calculateMonthlyCostsForVehicle = ({
  vehicle,
  upcomingCostEvents,
  selectedCosts,
}: {
  vehicle: Vehicle;
  upcomingCostEvents: UpcomingCostEvents;
  selectedCosts: CostsArr;
}) => {
  // Fetch a list of dates that are the next few months
  const dateArray: DateTime[] = generateDateArray();
  const recoccuringMonthlyCosts = baseMonthlyCosts({ vehicle, selectedCosts });
  const filteredEvents = upcomingCostEvents.filter(
    (event) => event.vehicleReg === vehicle.registrationNumber,
  );
  return groupEventsByDateTimeKey(
    filteredEvents,
    recoccuringMonthlyCosts,
    dateArray,
    selectedCosts,
  );
};

export const calculateMonthlyCosts = ({
  vehicles,
  upcomingCostEvents,
  selectedCosts,
}: {
  vehicles: Vehicle[];
  upcomingCostEvents: UpcomingCostEvents;
  selectedCosts: CostsArr;
}): Array<ExpenseData> => {
  const costs = vehicles.map((vehicle) =>
    calculateMonthlyCostsForVehicle({
      vehicle,
      upcomingCostEvents,
      selectedCosts,
    }),
  );

  // Merge the objects and sum the respective values
  const mergedCosts = costs.reduce((acc, record) => {
    Object.keys(record).forEach((key) => {
      if (acc[key]) {
        acc[key].totalCost += record[key].totalCost;
        acc[key].events = acc[key].events.concat(record[key].events);
      } else {
        acc[key] = {
          totalCost: record[key].totalCost,
          events: record[key].events,
        };
      }
    });
    return acc;
  }, {});

  return Object.entries(mergedCosts).reduce((acc: Array<ExpenseData>, val) => {
    return [
      ...acc,
      {
        month: DateTime.fromISO(val[0]).toFormat("LLL"),
        amount: parseFloat(val[1].totalCost.toFixed(2)),
      },
    ];
  }, []);
};

export interface ExpenseData {
  month: string;
  amount: number;
  fill?: string;
}
