import * as t from "io-ts";

export const VehicleAPIInfoC = t.type({
  vehicleRegistration: t.string,
  fuelCostPerMile: t.number,
  make: t.string,
  model: t.string,
  colour: t.string,
  parkingCost: t.number,
  taxCost: t.number,
  ulez: t.number,
  vehicleAge: t.number,
  vehicleMilage: t.number,
  AnnualVehicleMilage: t.number,
  estimatedValue: t.number,
  valueNextYear: t.number,
});

export type VehicleAPIInfoC = t.TypeOf<typeof VehicleAPIInfoC>;
