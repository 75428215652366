import * as E from "fp-ts/Either";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import "../../App.css";
import { fetchBasicDatafTE } from "../../apis/vehicleData/basicData/fetch";
import { colourPalette } from "../../components/shared/colourPalette";
import { IconsList } from "../../components/shared/graphsAndIcons/iconsList";
import { InternalLoading } from "../../components/shared/graphsAndIcons/spinner";
import { standardiseNumberPlate } from "../../components/shared/standardisations";
import { customButtonStyles } from "../../components/styles/generalStyles";
import { pages } from "../../routing";
import { Vehicle } from "../../types/frontend/vehicles";
import { VehicleRegInput } from "../../components/shared/addCar/addCar";
import { CostOverview } from "../costs/costs";
import { Depreciation } from "../depreciation/depreciation";
import { MaintenanceMock } from "../maintenance.tsx/maintenenceMock";
import { Settings } from "../settings/settings";
import { PageIcons } from "../cars";
import { WebLandingPage } from "../landingPages/webLanding/webLanding";

const mockVehicle: Vehicle = {
  registrationNumber: "AB12 XYZ",
  make: "Volkwagen",
  model: "Polo",
  vehicleDetails: {
    yearOfManufacture: 2018,
    motStatus: "Valid",
    motExpiryDate: "2024-08-20",
    co2EmissionsPerKGperKM: 0.12,
    fuelType: "Petrol",
    colour: "Silver",
    taxDueDate: "2024-05-01",
    taxStatus: "Paid",
  },
  MOTHistory: [],
  calculatedVehicleCosts: {
    estimatedCurrentValue: 15000,
    vehicleAge: 4,
    vehicleMilage: 30000,
    lastAnnualVehicleMilage: 10000,
    fuelCostPerMile: 0.1,
    ulezRequired: false,
    parking: 500,
    MOT: 50,
    roadTax: 200,
    vehicleValueEquation: [
      {
        make: "VOLKSWAGEN",
        model: "POLO",
        age_Coefficient: -4613.5276813752,
        miles_Coefficient: -1080.1835152185,
        fuel_Petrol_Coefficient: -596.7916573837,
        intercept: 27539.7671750697,
        ageRelationship: "logarithmic",
        milageRelationship: "logarithmic",
        fuelRelationship: "constant",
        updatedAt: "2023-12-29T16:00:00.025",
      },
    ],
  },
  usage: {
    zone1resident: false,
    primaryUserAge: 35,
    userDefinedAnnualMileage: 12000,
    ulezJourneys: "Weekly",
    congestionChargeJourneys: "Never",
    updatedAt: "2023-12-01",
  },
};

const SignupModal = ({
  setSelectedPage,
}: {
  setSelectedPage: Dispatch<SetStateAction<PageIcons>>;
}) => {
  const navigate = useNavigate();
  return (
    <Modal show={true} centered>
      <Modal.Header
        closeButton
        style={{ borderBottom: "none" }}
        onClick={() => setSelectedPage("Cost Overview")}
      >
        <Modal.Title
          style={{
            ...pageTitleStyles,
            marginTop: "0rem",
            marginBottom: "0rem",
          }}
        >
          Signup
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          paddingTop: "0px",
        }}
      >
        For deprection forecasts, MOT Failure predictions and more accurate
        costings, please create a free account.
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none", padding: "0px" }}>
        <Button
          style={customButtonStyles}
          onClick={() => {
            navigate(pages.login);
          }}
        >
          Signup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const containerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
};

const centeredContent: React.CSSProperties = {
  textAlign: "center",
  maxWidth: "600px",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const pageTitleStyles: React.CSSProperties = {
  color: colourPalette.darkGreyBackGround,
  marginTop: "1rem", // Add padding to the top as needed
  marginBottom: "1rem",
  textAlign: "center", // Align text to the center
  fontSize: "2rem", // Adjust the font size as needed
  fontFamily: "Roboto", // Replace 'Your-Modern-Font' with the desired modern font
};

export const TrialPage = () => {
  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    return <TrialPageMobile />;
  } else {
    return <WebLandingPage />;
  }
};

export const TrialPageMobile = () => {
  const [selectedPage, setSelectedPage] = useState<PageIcons>("Cost Overview");
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [showModal, setShowModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [registrationNumberInput, setRegistrationNumber] = useState<string>("");
  const setVehicleRegWithCheck = (input: string) => {
    setRegistrationNumber(standardiseNumberPlate(input));
  };
  const [apiloading, setApiloading] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<Vehicle>(mockVehicle);

  const callAPI = () => {
    fetchBasicDatafTE({ vehicleReg: registrationNumberInput })().then(
      E.fold(
        (x) => {
          setApiloading(false);
          setErrorMessage("Unable to find Vehicle, please try another.");
        },
        (data) => {
          setShowModal(false);
          return setVehicle(data);
        }
      )
    );
  };
  useEffect(() => {
    switch (selectedPage) {
      case "Cost Overview":
        setIcon(
          <div
            style={{
              width: "90vw",
            }}
          >
            <CostOverview vehicle={vehicle} />
          </div>
        );
        break;
      case "Depreciation":
        setIcon(
          <>
            <div
              style={{
                width: "90vw",
              }}
            >
              <SignupModal setSelectedPage={setSelectedPage} />
              <Depreciation vehicle={vehicle} />
            </div>
          </>
        );

        break;
      case "Maintenance":
        setIcon(
          <div
            style={{
              width: "90vw",
            }}
          >
            <SignupModal setSelectedPage={setSelectedPage} />
            <MaintenanceMock />
          </div>
        );
        break;
      case "Usage":
        setIcon(
          <>
            <SignupModal setSelectedPage={setSelectedPage} />
            <Settings vehicle={vehicle} />;
          </>
        );
        break;
      default:
        setIcon(null);
    }
  }, [selectedPage, vehicle]);
  useEffect(() => {}, [icon]);

  const subTitleStyles: React.CSSProperties = {
    color: colourPalette.darkGreyBackGround,
    textAlign: "left",
    fontSize: "1.2rem",
    fontFamily: "Roboto",
  };
  return (
    <div style={containerStyles}>
      <div style={centeredContent}>
        <h1 style={pageTitleStyles}>{vehicle.registrationNumber}</h1>
        <h1 style={subTitleStyles}>
          {vehicle.make} {vehicle.model}
        </h1>
        <IconsList
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
        {icon}
      </div>
      <Modal show={showModal} centered>
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title
            style={{
              ...pageTitleStyles,
              marginTop: "0rem",
              marginBottom: "0rem",
            }}
          >
            Vehicle Registration
          </Modal.Title>
        </Modal.Header>
        {apiloading ? (
          <InternalLoading />
        ) : (
          <>
            <Modal.Body
              style={{
                paddingTop: "0px",
              }}
            >
              {errorMessage ?? ""}
              <VehicleRegInput
                placeholder={"AB12XYZ"}
                value={registrationNumberInput ?? ""}
                setValue={setVehicleRegWithCheck}
                callAPI={() => callAPI()}
                disabled={apiloading}
              />
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "none", padding: "0px" }}>
              <Button
                style={{ ...customButtonStyles, width: "30vw" }}
                onClick={() => {
                  callAPI();
                }}
              >
                Find
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};
