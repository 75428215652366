import { FrequencyOfJourneys } from "../../types/frontend/vehicles";

export function interpolateCost(age: number): number {
  // Define the data points
  const ages = [17, 20, 25, 35, 45, 55, 65, 75];
  const costs = [851, 851, 719, 639, 575, 468, 491, 752];

  // Check if the age is outside the range
  if (age < ages[0]) return costs[0];
  if (age > ages[ages.length - 1]) return costs[ages.length - 1];

  // Find the two nearest data points
  let startIndex = 0;
  while (age > ages[startIndex + 1]) {
    startIndex++;
  }

  const x0 = ages[startIndex];
  const x1 = ages[startIndex + 1];
  const y0 = costs[startIndex];
  const y1 = costs[startIndex + 1];

  // Linear interpolation formula
  const interpolatedCost = y0 + ((age - x0) / (x1 - x0)) * (y1 - y0);

  return interpolatedCost;
}

export const calculateMaintenanceCostMultiplier = (currentMileage: number) => {
  let maintenanceCostMultiplier;
  // According to your mechanic but /2 as they might include fuel costs
  if (currentMileage < 25000) {
    maintenanceCostMultiplier = 1150;
  } else if (currentMileage < 50000) {
    maintenanceCostMultiplier = 1800;
  } else if (currentMileage < 75000) {
    maintenanceCostMultiplier = 2500;
  } else if (currentMileage < 100000) {
    maintenanceCostMultiplier = 3200;
  } else if (currentMileage < 125000) {
    maintenanceCostMultiplier = 3350;
  } else if (currentMileage < 150000) {
    maintenanceCostMultiplier = 3600;
  } else if (currentMileage < 175000) {
    maintenanceCostMultiplier = 3950;
  } else {
    maintenanceCostMultiplier = 4100;
  }
  return maintenanceCostMultiplier / 2;
};

export function getOccurrencesPerWeek(frequency: FrequencyOfJourneys): number {
  switch (frequency) {
    case "Never":
      return 0;
    case "Monthly":
      return 0.25; // Assuming an average of 4 weeks in a month
    case "Fortnightly":
      return 0.5;
    case "Weekly":
      return 1;
    case "Every other day":
      return 2.5; // Assuming an average of 3.5 times in a week
    case "Daily":
      return 5;
    default:
      return 0; // If the input is not a valid frequency, return 0.
  }
}
