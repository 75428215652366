import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { colourPalette } from "../../components/shared/colourPalette";
import { MyRangeSliderWithRange } from "../../components/shared/graphsAndIcons/slider";
import {
  ExampleChart,
  MileageData,
  estimateVehicleValueEquation,
  generateMileageTimeSeries,
} from "../../components/shared/graphsAndIcons/valueForecast";
import { titleStyle } from "../../components/styles/generalStyles";
import { Vehicle } from "../../types/frontend/vehicles";

const Animation = ({
  n,
  subtitle,
  tension = 50,
  symbol = "",
}: {
  n: number;
  subtitle: string;
  symbol?: string;
  tension?: number;
}) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: n },
    config: { tension: tension, damping: 10 }, // Adjust tension and friction values
  });

  const subTitleStyles: React.CSSProperties = {
    color: colourPalette.darkGreyBackGround,
    fontSize: "1rem", // Adjust the font size as needed
    fontFamily: "Roboto", // Replace 'Your-Modern-Font' with the desired modern font
  };

  return (
    <div style={{ padding: "0 1rem", display: "flex", flexDirection: "column" }}>
      <h1 style={{ ...titleStyle, fontSize: "2rem", marginTop: "1rem" }}>
        <animated.text
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="#81b2f1"
        >
          {number.to((n) => symbol + n.toFixed(2))}
        </animated.text>
      </h1>
      <h1 style={subTitleStyles}>{subtitle}</h1>
    </div>
  );
};

const DepreciationGraph = ({
  vehicle,
  annualMileage,
}: {
  vehicle: Vehicle;
  annualMileage: number;
}) => {
  const currentYear = DateTime.local().year;

  const [graphData, setGraphData] = useState<
    {
      year: DateTime;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    const timeSeriesData: MileageData[] | undefined = generateMileageTimeSeries(
      {
        annualMileageNow: annualMileage,
        isPetrol: vehicle.vehicleDetails.fuelType?.toLowerCase() == "petrol",
        vehicle,
      }
    );
    let costsTemp: Array<{ year: DateTime; value: number }> =
      timeSeriesData.map((x) => {
        if (x.age == 0) {
          return {
            year: x.year,
            value: 0,
          };
        }
        return {
          year: x.year,
          value: estimateVehicleValueEquation({
            data: {
              mileage: x.mileage,
              age: x.age,
              isPetrol: vehicle.vehicleDetails.fuelType == "Petrol",
            },
            equation: vehicle.calculatedVehicleCosts.vehicleValueEquation[0],
          }),
        };
      });
    const [year0, year1, year2, ...remainingYears] = costsTemp;

    const interpolateYear0 = (year1: number, year2: number): number => {
      const value_year0 = year1 + (year2 - year1) * ((0 - 1) / 1);
      return value_year0;
    };

    const inputdata = [
      { year: year0.year, value: interpolateYear0(year1.value, year2.value) },
      year1,
      year2,
      ...remainingYears,
    ];
    setGraphData(inputdata);
  }, [annualMileage]);

  return graphData.length > 0 ? (
    <ExampleChart
      inputdata={graphData.filter(
        (item) =>
          currentYear - 5 < item.year.year && item.year.year < currentYear + 5
      )}
    />
  ) : null;
};

export const Depreciation = ({ vehicle }: { vehicle: Vehicle }) => {
  const [annualMileage, setMileage] = useState<number>(
    vehicle.usage.userDefinedAnnualMileage ??
      vehicle.calculatedVehicleCosts.vehicleMilage
  );

  const [FiveYears, setFiveYears] = useState<string>("1");

  useEffect(() => {
    setFiveYears(
      estimateVehicleValueEquation({
        data: {
          mileage:
            vehicle.calculatedVehicleCosts.vehicleMilage + 5 * annualMileage,
          age: vehicle.calculatedVehicleCosts.vehicleAge + 5,
          isPetrol: vehicle.vehicleDetails.fuelType?.toLowerCase() == "petrol",
        },
        equation: vehicle.calculatedVehicleCosts.vehicleValueEquation[0],
      }).toString()
    );
  }, [annualMileage]);

  return (
    <div
      style={{
        margin: "0 1rem",
        maxHeight: "100vh",
        border: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          margin: "0rem 10vw",
        }}
      >
        <Animation
          n={vehicle.calculatedVehicleCosts.estimatedCurrentValue}
          subtitle={"Current Value"}
          symbol="£"
        />
        <Animation
          n={parseFloat(FiveYears)}
          subtitle={"Value in 5 Years"}
          tension={1000}
          symbol="£"
        />
      </div>

      <h1
        style={{
          color: colourPalette.darkGreyBackGround,
          textAlign: "left",
          fontSize: "1rem",
          fontFamily: "Roboto",
          marginTop: "1rem",
        }}
      >
        {"Current Mileage Estimate: " +
          vehicle.calculatedVehicleCosts.vehicleMilage.toLocaleString()}
      </h1>

      <MyRangeSliderWithRange
        title={`Annual Mileage:  ${annualMileage.toLocaleString()}`}
        min={0}
        max={25000}
        step={5000}
        initialValue={annualMileage}
        onValueChange={setMileage}
        roundTo={100}
        lastYearValue={vehicle.calculatedVehicleCosts.lastAnnualVehicleMilage}
      />
      <DepreciationGraph vehicle={vehicle} annualMileage={annualMileage} />
    </div>
  );
};
