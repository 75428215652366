import { useAuth, useUser } from "@clerk/clerk-react";
import * as E from "fp-ts/Either";
import React, { useEffect, useState } from "react";
import { useVehicleContext } from "../App";
import { fetchCarsfTE } from "../apis/userCars/fetchCars";
import {
  barChartColours,
  colourPalette,
} from "../components/shared/colourPalette";
import { InternalLoading } from "../components/shared/graphsAndIcons/spinner";
import { Cars, PageIcons } from "./cars";
import { Home } from "./home";
import { WebLandingPage } from "./landingPages/webLanding/webLanding";
import { InternalTab, TabBar } from "./tabBar/tabBar";
import { UpcomingCosts } from "./upcomingCosts/upcomingCostsHome";
import { IconsList } from "../components/shared/graphsAndIcons/iconsList";

export const LoggedInHome = () => {
  const [activeTab, setActiveTab] = useState<InternalTab>("Home");
  const [carPage, setCarPage] = useState<string>("Cars");
  const [selectedIconPage, setSelectedIconPage] =
    useState<PageIcons>("Cost Overview");

  const handleTabClick = (tab: InternalTab) => {
    setActiveTab(tab);
  };

  const { user } = useUser();
  const { getToken } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const { vehicles, setVehicles } = useVehicleContext();

  useEffect(() => {
    fetchCarsfTE({ uuid: user?.id ?? "", getToken })().then(
      E.fold(
        (x) => {
          setLoading(false);
          return setVehicles([]);
        }, // TODO return the actual error here,
        (data) => {
          setLoading(false);
          return setVehicles(data);
        }
      )
    );
  }, []);

  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const selectVehicle = (vehicleReg: string) => {
    setActiveTab("Cars");
    setCarPage(vehicleReg);
  };

  const navigateToVehicleMOTPage = (vehicleReg: string) => {
    setActiveTab("Cars");
    setCarPage(vehicleReg);
    setSelectedIconPage("Maintenance");
  };

  let selectedTab: React.ReactNode;
  let title: string;

  const pageTitleStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    padding: "1rem",
    boxSizing: "border-box",
    zIndex: 10,
    color: colourPalette.darkGreyBackGround,
    textAlign: "center", // Align text to the center
    fontSize: "2rem", // Adjust the font size as needed
    fontFamily: "Roboto", // Replace 'Your-Modern-Font' with the desired modern font
    height: "4rem",
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Box shadow for a slight border effect
  };

  switch (activeTab) {
    case "Home":
      selectedTab = (
        <Home
          vehicles={vehicles}
          selectVehicle={selectVehicle}
          setActiveTab={setActiveTab}
        />
      );

      title = "Annual Running Costs";
      break;
    case "Costs":
      selectedTab = (
        <UpcomingCosts
          vehicles={vehicles}
          navigateToVehicleMOTPage={navigateToVehicleMOTPage}
        />
      );
      title = "Upcoming Costs";
      break;
    case "Cars":
      selectedTab = (
        <Cars
          vehicles={vehicles}
          carPage={carPage}
          setCarPage={setCarPage}
          selectedIconPage={selectedIconPage}
          setSelectedIconPage={setSelectedIconPage}
        />
      );
      title = carPage;

      break;
    default:
      selectedTab = null;
      title = "Not Found";
  }

  if (!isMobile) {
    return <WebLandingPage />;
  } else {
    return loading ? (
      <InternalLoading />
    ) : (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            ...pageTitleStyle,
            
          }}
        >
          <h1>{title}</h1>
          {activeTab == "Cars" && carPage != "Cars" ? (
            <IconsList
              selectedPage={selectedIconPage}
              setSelectedPage={setSelectedIconPage}
            />
          ) : null}
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            bottom: 0,
            overflowY: "auto",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          {selectedTab}
          <TabBar handleTabClick={handleTabClick} activeTab={activeTab} />
        </div>
      </div>
    );
  }
};
