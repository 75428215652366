import Slider from "rc-slider";
import "rc-slider/assets/index.css"; // Import the styles for rc-slider
import React, { Dispatch, SetStateAction, useState } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import { colourPalette } from "../colourPalette";
import { InfoCircleData } from "./infoCircle";

interface MyRangeSliderWithRangeProps {
  title: string;
  min: number;
  max: number;
  step: number;
  initialValue: number;
  onValueChange: Dispatch<SetStateAction<number>>;
  roundTo: number;
  lastYearValue?: number;
  infoCircle?: string;
}

export const MyRangeSliderWithRange: React.FC<MyRangeSliderWithRangeProps> = ({
  title,
  min,
  max,
  step,
  initialValue,
  onValueChange,
  roundTo,
  lastYearValue,
  infoCircle,
}) => {
  const [sliderValue, setSliderValue] = useState<number>(initialValue);
  const infoCircleData = infoCircle ? (
    <InfoCircleData text={infoCircle} />
  ) : null;

  const handleSliderChange = (value: number | number[]) => {
    if (typeof value === "number") {
      const roundedValue = Math.round(value / roundTo) * roundTo;
      setSliderValue(roundedValue);
      onValueChange(roundedValue);
    }
  };

  const subtitleStyle: React.CSSProperties = {
    color: colourPalette.darkGreyBackGround,
    textAlign: "left",
    fontSize: "1rem",
    marginBottom: "2.5rem",
    fontFamily: "Roboto",
  };

  // Generate an array of markers every 10 units from min to max
  let markers = [min];
  let value = Math.ceil(min / step) * step;

  while (value < max) {
    markers.push(value);
    value = value + step;
  }
  markers.push(max);

  return (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "3rem",
        width: "100%",
        position: "relative",
        fontFamily: "Roboto",
      }}
    >
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "2rem",
        }}
      >
        <h1 style={subtitleStyle}>{title}</h1>

        {infoCircleData}
      </div>
      <Slider
        min={min}
        max={max}
        value={sliderValue}
        onChange={(x) => handleSliderChange(x)}
        style={{
          width: "100%",
          border: "none",
        }}
        trackStyle={{
          backgroundColor: colourPalette.primary,
        }}
        handleStyle={{
          borderColor: colourPalette.primary,
          color: colourPalette.primary,
        }}
      />
      {lastYearValue ? (
        <div
          key={7000}
          style={{
            marginTop: "-1.5rem",
            position: "absolute",
            left: `${((lastYearValue - min) / (max - min)) * 100}%`,
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: colourPalette.darkGreyBackGround,
          }}
        >
          <CaretDownFill
            style={{
              width: "1rem",
              height: "1rem",
              marginBottom: "0.5rem",
            }}
          />
          <span
            style={{
              marginTop: "-2.5rem",
              fontSize: "0.7rem",
              fontWeight: "bold",
              fontFamily: "Roboto",
            }}
          >
            {"Last year"}
          </span>
        </div>
      ) : null}
      {markers.map((marker) => (
        <div
          key={marker}
          style={{
            position: "absolute",
            left: `${((marker - min) / (max - min)) * 100}%`,
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: colourPalette.darkGreyBackGround,
          }}
        >
          <div
            style={{
              width: "2px",
              height: marker !== min && marker !== max ? ".5rem" : ".7rem",
              backgroundColor: colourPalette.darkGreyBackGround,
              marginBottom: "0.5rem",
            }}
          />
          <span
            style={{
              fontSize: "0.7rem",
              fontWeight: marker !== min && marker !== max ? "normal" : "bold",
              fontFamily: "Roboto",
            }}
          >
            {marker}
          </span>
        </div>
      ))}
    </div>
  );
};
