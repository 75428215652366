import { Button, Card } from "react-bootstrap";
import { colourPalette } from "../colourPalette";
import { useSpring, animated } from "react-spring";

import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { DateTime } from "luxon";

const Animation = ({ n, cx, cy }: { n: number; cx?: number; cy?: number }) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: n },
    config: { tension: 50, damping: 1000 }, // Adjust tension and friction values
  });

  return (
    <animated.text
      x={cx}
      y={cy ? cy : 0}
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
      alignmentBaseline="middle"
      fill="#81b2f1"
      fontSize="46"
      fontWeight="600"
    >
      {number.to((n) => "£" + n.toFixed(0))}
    </animated.text>
  );
};

const CustomLabel = ({
  viewBox,
  labelText,
  value,
}: {
  viewBox: { cx?: number; cy?: number };
  labelText: string;
  value: number;
}) => {
  const { cx, cy } = viewBox;

  return (
    <g>
      <Animation n={value} cx={cx} cy={cy} />
      <text
        x={cx}
        y={(cy ? cy : 0) + 30}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="10"
        fontFamily="Roboto"
      >
        {labelText}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: colourPalette.senary,
          border: undefined,
          padding: "1px",
          paddingBottom: "0.1px",
        }}
      >
        <p
          style={{ color: "white", alignContent: "center" }}
          className="label"
        >{`${payload[0].name}: £${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export const GenericPieChart = ({
  data,
  innerRadius,
  outerRadius,
  labelText,
  labelValue,
}: {
  data: Array<{ name: string; value: number }>;
  innerRadius?: number;
  outerRadius?: number;
  labelText: string;
  labelValue: number;
}) => {
  const COLORS = Object.values(colourPalette);

  return (
    <ResponsiveContainer>
      <PieChart style={{ backgroundColor: colourPalette.lightGreyBackGround }}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={innerRadius ?? 80}
          outerRadius={outerRadius ?? 100}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label
            content={
              <CustomLabel
                viewBox={{ cx: undefined, cy: undefined }}
                labelText={labelText}
                value={labelValue}
              />
            }
            position="center"
          />
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export const PieChartWithHeaderandCard = ({
  data,
  innerRadius,
  outerRadius,
  labelText,
  labelValue,
}: {
  data: Array<{ name: string; value: number }>;
  innerRadius?: number;
  outerRadius?: number;
  labelText: string;
  labelValue: number;
}) => {
  return (
    <Card
      style={{
        height: "25rem",
        border: "none",
        backgroundColor: colourPalette.lightGreyBackGround,
      }}
    >
      <h1
        style={{
          paddingTop: "1rem",
          color: colourPalette.darkGreyBackGround,
          textAlign: "center",
          fontSize: "1rem",
          fontFamily: "Roboto",
        }}
      >
        {DateTime.now().year.toString()}
      </h1>

      <GenericPieChart
        data={data}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        labelText={labelText}
        labelValue={labelValue}
      />
    </Card>
  );
};
