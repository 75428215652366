import React from "react";
import { CaretRightFill } from "react-bootstrap-icons";
import { colourPalette } from "../../components/shared/colourPalette";
import { titleStyle } from "../styles/generalStyles";
import { constVoid } from "fp-ts/lib/function";

const cardStyles: React.CSSProperties = {
  padding: "1rem",
  borderRadius: "1rem",
  boxShadow: "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  fontFamily: "Roboto",
  overflow: "hidden",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const titleContainerStyles: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  margin: "0.5rem",
  flex: 1,
};

const titleTextStyles: React.CSSProperties = {
  ...titleStyle,
  color: colourPalette.darkGreyBackGround,
  fontSize: "1.5rem",
  marginLeft: "0.5rem", // Add margin to the left to move text to the left
};

const iconStyles: React.CSSProperties = {
  color: colourPalette.primary,
  fontSize: "1.5rem",
  marginRight: "0.5rem",
};

interface CardProps {
  title: string;
  onClick?: () => void;
}

export const HomePageCard: React.FC<CardProps> = ({
  title,
  onClick = constVoid,
}) => {
  return (
    <div style={cardStyles} onClick={() => onClick()}>
      <div style={titleContainerStyles}>
        <div style={titleTextStyles}>{title}</div>
      </div>
      <CaretRightFill style={iconStyles} onClick={() => onClick()} />
    </div>
  );
};
