import { useAuth, useUser } from "@clerk/clerk-react";
import * as E from "fp-ts/Either";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Check, Trash3Fill } from "react-bootstrap-icons";
import { AddCarTE } from "../../apis/userCars/addCars";
import { fetchByVehicleInfoTE } from "../../apis/userCars/fetchVehicleInfo/fetchVehicleInfo";
import { CardWithDelete } from "../../components/cards/cardWithDelete";
import { colourPalette } from "../../components/shared/colourPalette";
import { InternalLoading } from "../../components/shared/graphsAndIcons/spinner";
import { standardiseNumberPlate } from "../../components/shared/standardisations";
import {
  customButtonStyles,
  pageTitleStyle,
} from "../../components/styles/generalStyles";
import { VehicleRegInput } from "../shared/addCar/addCar";

export const AddVehileModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user } = useUser();
  const { getToken } = useAuth();

  const [registrationNumberInput, setRegistrationNumber] = useState<string>("");

  const setVehicleRegWithCheck = (input: string) => {
    setRegistrationNumber(standardiseNumberPlate(input));
  };
  const [newVehicle, setNewVehicle] = useState<
    | {
        registrationNumber: string;
        value: number;
        colour: string;
        make: string;
        model: string;
      }
    | undefined
  >();

  const [errorMessage, setError] = useState<string | undefined>(undefined);

  const [apiloading, setApiloading] = useState<boolean>(false);

  const iconContainerStyles: React.CSSProperties = {
    display: "flex",
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
    color: "white",
    fontWeight: "bold",
    padding: "0.75rem",
    fontSize: "1.5rem",
    borderRadius: "0.5rem",
    marginTop: "2rem",
    margin: "0.5rem",
    flex: 1, // Take up the entire width
  };

  const trashIconContainerStyles: React.CSSProperties = {
    ...iconContainerStyles,
    backgroundColor: colourPalette.reject,
  };

  const checkIconContainerStyles: React.CSSProperties = {
    ...iconContainerStyles,
    fontSize: "2rem",
    backgroundColor: colourPalette.accept,
  };

  const acceptReject = () => (
    <div
      style={{
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center", // Center horizontally
        transition: "background-color 0.6s ease",
      }}
    >
      <div
        style={trashIconContainerStyles}
        onClick={() => {
          setRegistrationNumber("");
          return setNewVehicle(undefined);
        }}
      >
        <Trash3Fill />
      </div>
      <div
        style={checkIconContainerStyles}
        onClick={() => {
          if (newVehicle) {
            AddCarTE({
              uuid: user?.id ?? "",
              getToken,
              registrationNumber: newVehicle?.registrationNumber,
            })().then(handleRefresh);
          }
          setNewVehicle(undefined);
        }}
      >
        <Check />
      </div>
    </div>
  );

  const searchVehicleAPI = () => {
    setApiloading(true);

    fetchByVehicleInfoTE({
      vehicleReg: registrationNumberInput,
      getToken,
    })().then(
      E.fold(
        (err) => {
          setError(
            "Sorry, the vehicle type you're looking for isn't available yet. Thank you for your patience."
          );
          setApiloading(false);
        }, // TODO return the actual error here,
        (data) => {
          setNewVehicle({
            registrationNumber: registrationNumberInput,
            value: data.estimatedValue,
            colour: data.colour,
            make: data.make,
            model: data.model,
          });
          setError(undefined)
          setApiloading(false);
        }
      )
    );
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  const handleClose = () => setShowModal(false);

  return newVehicle ? (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          style={{
            borderBottom: "none",
            marginBottom: "0rem",
            paddingBottom: "0rem",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              ...pageTitleStyle,
              marginTop: "0rem",
              marginBottom: "0rem",
            }}
          >
            Add Vehicle
          </Modal.Title>
        </Modal.Header>
        <CardWithDelete
          title={newVehicle.registrationNumber}
          value={`£${newVehicle.value.toFixed(0)}`}
          text={`${newVehicle.colour}, ${newVehicle.make}, ${newVehicle.model}`}
          valueSubTitle={"Estimated Value"}
          infoCircle={
            "Vehicle valuations are generated for base models and therefore may not reflect less common models accurately."
          }
          element={acceptReject}
          deleteEnabled={false}
          shadow={false}
        />{" "}
      </Modal>
    </>
  ) : (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header style={{ borderBottom: "none" }} closeButton>
        <Modal.Title
          style={{
            ...pageTitleStyle,
            marginTop: "0rem",
            marginBottom: "0rem",
          }}
        >
          Add Vehicle
        </Modal.Title>
      </Modal.Header>
      {apiloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InternalLoading />
        </div>
      ) : (
        <>
          <Modal.Body
            style={{
              paddingTop: "0px",
            }}
          >
            {errorMessage ?? ""}
            <VehicleRegInput
              placeholder={"AB12XYZ"}
              value={registrationNumberInput ?? ""}
              setValue={setVehicleRegWithCheck}
              callAPI={() => searchVehicleAPI()}
              disabled={apiloading}
            />
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", padding: "0px" }}>
            <Button
              style={{ ...customButtonStyles, width: "30vw" }}
              onClick={() => {
                searchVehicleAPI();
              }}
            >
              Find
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
