export const standardiseNumberPlate = (input: string) => {
  // Removes non standard characters and converts to uppercase

  const alphanumericRegex = /[^a-zA-Z0-9]/g;
  const sanitizedString = input.replace(alphanumericRegex, "");
  return sanitizedString.toUpperCase();
};

/**
 * Function to capitalize the first word in a string.
 *
 * @param str The input string to be capitalized.
 * @returns A new string with the first word capitalized.
 */
export const capitalizeFirstWord = (str: string): string => {
  // Check if the string is not empty
  if (str.length === 0) return str;

  // Capitalize the first character and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};