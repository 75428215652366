export const colourPalette = {
  primary: "#6e4ce5",
  secondary: "#81b2f1",
  tertiary: "#261073",
  quaternary: "#2c1385",
  quinary: "#cfb9c4",
  senary: "#39343b",
  accept: "#80ff80",
  reject: "#ff8080",
  lightGreyBackGround: "#f8f9fa",
  mediumGrey: "#d5d6d7",
  darkGreyBackGround: "#6a6a6b",
};

export const barChartColours = {
  bars: colourPalette.tertiary,
  accentLines: colourPalette.secondary,
  lightText: colourPalette.lightGreyBackGround,
  mediumText: colourPalette.mediumGrey,
  horizontalLines: "#84888c",
};

export const gradientBackground = `linear-gradient(-45deg, rgba(110,76,229,1) 0%,  rgba(129,178,241,1) 150%)`;
