import { BarChartFill, CarFrontFill, HouseFill } from "react-bootstrap-icons";
import {
  barChartColours,
  colourPalette,
} from "../../components/shared/colourPalette";

export type InternalTab = "Home" | "Costs" | "Cars";

export const TabBar = ({
  handleTabClick,
  activeTab,
}: {
  handleTabClick: (tab: InternalTab) => void;
  activeTab: InternalTab;
}) => {
  const textStyle: React.CSSProperties = {
    textAlign: "center",
    fontSize: "1rem",
    padding: "0.2rem 0.1rem 0.2rem 0.1rem",
  };
  return (
    <div
      style={{
        position: "fixed",
        height: "5rem",
        borderRadius: "1rem 1rem 0rem 0rem",
        bottom: 0,
        left: 0,
        width: "100%",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Box shadow for a slight border effect
        backgroundColor: "#f6f6f7",

        display: "flex",
        justifyContent: "space-around",
        paddingTop: "0.5rem",
        paddingBottom: "1.5rem",
        zIndex: "10",
      }}
    >
      <button
        onClick={() => handleTabClick("Costs")}
        style={{
          textDecoration: "none",
          color:
            activeTab === "Costs"
              ? colourPalette.primary
              : colourPalette.darkGreyBackGround,
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
      >
        <BarChartFill
          style={{
            width: activeTab === "Costs" ? "100%" : "80%",
            height: activeTab === "Costs" ? "100%" : "80%",
          }}
        />
        <div style={textStyle}>{"Costs"}</div>
      </button>
      <button
        onClick={() => handleTabClick("Home")}
        style={{
          textDecoration: "none",
          color:
            activeTab === "Home"
              ? colourPalette.primary
              : colourPalette.darkGreyBackGround,
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
      >
        <HouseFill
          style={{
            width: activeTab === "Home" ? "100%" : "80%",
            height: activeTab === "Home" ? "100%" : "80%",
          }}
        />
        <div style={textStyle}>{"Home"}</div>
      </button>
      <button
        onClick={() => handleTabClick("Cars")}
        style={{
          textDecoration: "none",
          color:
            activeTab === "Cars"
              ? colourPalette.primary
              : colourPalette.darkGreyBackGround,
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
      >
        <CarFrontFill
          style={{
            width: activeTab === "Cars" ? "100%" : "80%",
            height: activeTab === "Cars" ? "100%" : "80%",
          }}
        />
        <div style={textStyle}>{"Cars"}</div>
      </button>
    </div>
  );
};
