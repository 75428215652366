import * as E from "fp-ts/Either";
import * as TE from "fp-ts/TaskEither";
import { pipe } from "fp-ts/lib/function";
import { VehicleWireC } from "../../../types/wire/vehicles";
import { InternalError } from "../../../types/frontend/errros";
import { Vehicle, fromWire } from "../../../types/frontend/vehicles";
import { urlBasePath } from "../../../components/shared/graphsAndIcons/env/vars";
import { flow } from "fp-ts/function";

export const fetchBasicDatafTE = ({
  vehicleReg,
}: {
  vehicleReg: string;
}): TE.TaskEither<InternalError, Vehicle> => {
  const url = `${urlBasePath}//basicData/vehicle/${vehicleReg}`;

  return pipe(
    TE.tryCatch(
      async () => {
        return fetch(url, {
          method: "get",
        }).then(async (response) => {
          if (response.status !== 200) {
            return E.left("UnknownError" as InternalError);
          } else {
            return E.right(await response.json());
          }
        });
      },
      (err): any => {
        console.warn("error sending the request:", JSON.stringify(err));
        return "FailedToSend";
      },
    ),
    TE.chain(
      flow(
        E.chain((rsp) =>
          pipe(
            JSON.parse(rsp.data),
            VehicleWireC.decode,
            E.bimap((): InternalError => "UnknownError", fromWire),
          ),
        ),
        TE.fromEither,
      ),
    ),
  );
};
