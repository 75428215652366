import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/function";
import { InternalError } from "../../types/frontend/errros";
import {
  MotFailureMetaData,
  mapFromWire,
} from "../../types/frontend/MOTLikeliHoods";
import { MotFailureMetaDataC } from "../../types/wire/MOTLikeliHoods";
import { urlBasePath } from "../../components/shared/graphsAndIcons/env/vars";

export const fetchMOTLikeliHoodsTE = ({
  ageDays,
  mileage,
  getToken,
}: {
  ageDays: number;
  mileage: number;
  getToken: any;
}): TE.TaskEither<InternalError, MotFailureMetaData> => {
  const url = `${urlBasePath}/ageDays/${ageDays}/mileage/${mileage}`;

  return pipe(
    TE.tryCatch(
      async () => {
        return fetch(url, {
          headers: {
            Authorization: `${await getToken({ template: "userId_JWT" })}`,
          },
          method: "get",
        }).then((response) => response.json());
      },
      (err): InternalError => {
        console.warn("Error sending the request:", JSON.stringify(err));
        return "FailedToSend";
      },
    ),
    TE.chain((jsondata) => {
      return pipe(
        TE.fromEither(MotFailureMetaDataC.decode(JSON.parse(jsondata.data))),
        TE.bimap(
          (err): InternalError => {
            return "UnknownError";
          },
          (x) => mapFromWire(x),
        ),
      );
    }),
  );
};
