import * as E from "fp-ts/Either";
import * as TE from "fp-ts/TaskEither";
import { pipe } from "fp-ts/lib/function";
import {
  UpcomingCostEvents,
  fromWireArr,
} from "../../../types/frontend/events";
import { InternalError } from "../../../types/frontend/errros";
import { UpcomingCostEventsWire } from "../../../types/wire/events";
import { urlBasePath } from "../../../components/shared/graphsAndIcons/env/vars";

export const fetchUpcomingCostEventsTE = ({
  uuid,
  getToken,
}: {
  uuid: string;
  getToken: any;
}): TE.TaskEither<InternalError, UpcomingCostEvents> => {
  const url = `${urlBasePath}/user/${uuid}/events`;

  return pipe(
    TE.tryCatch(
      async () => {
        return fetch(url, {
          headers: {
            Authorization: `${await getToken({ template: "userId_JWT" })}`,
          },
          method: "get",
        }).then((response) => response.json());
      },
      (err): any => {
        console.warn("error sending the request:", JSON.stringify(err));
        return "FailedToSend";
      },
    ),
    TE.chain((rsp) =>
      pipe(
        JSON.parse(rsp.data),
        UpcomingCostEventsWire.decode,
        E.bimap(
          (err): InternalError => {
            console.warn("Error", JSON.stringify(err));
            return "UnknownError";
          },
          (data): UpcomingCostEvents => fromWireArr(data),
        ),
        TE.fromEither,
      ),
    ),
  );
};
