import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { animated, useSpring } from "react-spring";
import { colourPalette } from "../../components/shared/colourPalette";
import { PieChartWithHeaderandCard } from "../../components/shared/graphsAndIcons/pieChart";
import {
  customButtonStyles,
  titleStyle,
} from "../../components/styles/generalStyles";
import { Vehicle } from "../../types/frontend/vehicles";
import { calculateVehicleCosts } from "./calculations";
import { InfoCircleData } from "../../components/shared/graphsAndIcons/infoCircle";

export const MOTandTax = ({
  n,
  type,
  expired,
  dueDate,
  tension = 50,
}: {
  n: number;
  type: "MOT" | "Tax";
  expired: boolean;
  dueDate: DateTime;
  tension?: number;
}) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: n },
    config: { tension: tension, damping: 10 }, // Adjust tension and friction values
  });

  const subTitleStyles: React.CSSProperties = {
    color: colourPalette.darkGreyBackGround,
    fontSize: "1rem", // Adjust the font size as needed
    fontFamily: "Roboto", // Replace 'Your-Modern-Font' with the desired modern font
  };

  return (
    <>
      <h1 style={{ ...titleStyle, fontSize: "2rem", marginTop: "1rem" }}>
        <animated.text
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="#81b2f1"
        >
          {number.to((n) => {
            if (expired) {
              return `${type} has expired`;
            } else {
              return `${type} due in ` + n.toFixed(0) + " days";
            }
          })}
        </animated.text>
      </h1>
      <h1 style={subTitleStyles}>{dueDate.toFormat("dd LLL yyyy")}</h1>
    </>
  );
};

const handleExpense = (expense: string): JSX.Element | null => {
  switch (expense) {
    case "depreciation":
      return (
        <InfoCircleData
          text={
            "Depreciation is estimated from the make and model of your vehicle and the annual mileage the car is driven."
          }
        />
      );
    case "insurance":
      return null;
    case "maintenance":
      return (
        <InfoCircleData
          text={
            "Maintenence costs are estimated from the vehicle age and mileage."
          }
        />
      );
    case "fuel":
      return (
        <InfoCircleData
          text={
            "Fuel is estimated from the annual mileage and vehicle efficiency."
          }
        />
      );
    case "parking":
      return (
        <InfoCircleData
          text={
            "Is calculated for the vehicle using on Lambeth council Emission-based parking charges."
          }
        />
      );
    case "MOT":
      return null;
    case "roadTax":
      return (
        <InfoCircleData
          text={
            "Is calculated for the vehicle using National Emission-based tax charges."
          }
        />
      );
    case "annualCongestionCharge":
      return (
        <InfoCircleData
          text={
            "Is estimated from vehicle usage behaviour, if this appears wrong, please update in the usage tab."
          }
        />
      );
    case "annualULEZ":
      return (
        <InfoCircleData
          text={
            "Is estimated from vehicle usage behaviour, if this appears wrong, please update in the usage tab."
          }
        />
      );
    default:
      return <InfoCircleData text={expense} />;
  }
};

export const CostOverview = ({ vehicle }: { vehicle: Vehicle }) => {
  const [costs, setCosts] = useState<Array<{ name: string; value: number }>>(
    [],
  );
  const [displayCosts, setDisplayCosts] = useState<
    Array<{ name: string; value: any }>
  >([]);

  const [totalCost, setTotalCost] = useState<number>(NaN);

  useEffect(() => {
    const { totalCost, displayCosts, costs } = calculateVehicleCosts({
      vehicle,
    });
    setTotalCost(totalCost);
    setDisplayCosts(displayCosts);
    setCosts(costs);
  }, [vehicle]);

  const subTitleStyles: React.CSSProperties = {
    color: colourPalette.primary,
    marginTop: "1rem",
    marginBottom: "1rem",
    fontSize: "1.5rem", // Adjust the font size as needed
    fontFamily: "Roboto", // Replace 'Your-Modern-Font' with the desired modern font
  };

  const [showBreakdown, setShowBreakdown] = useState<boolean>(false);
  const TaxDue = DateTime.fromISO(vehicle.vehicleDetails.taxDueDate ?? "");

  return (
    <>
      <MOTandTax
        n={TaxDue.diffNow("days").days}
        type="Tax"
        expired={DateTime.now() > TaxDue}
        dueDate={TaxDue}
      />
      <PieChartWithHeaderandCard
        data={costs}
        labelText={"Total Annual Cost"}
        labelValue={totalCost}
      />
      {showBreakdown ? (
        <>
          <h1 style={subTitleStyles}> {"AnnualBreakdown"}</h1>
          {displayCosts.map((x) => {
            const info = handleExpense(x.name);

            return (
              <h1
                style={{
                  color: colourPalette.darkGreyBackGround,
                  marginTop: "0.5rem",
                  fontSize: "1rem", // Adjust the font size as needed
                  fontFamily: "Roboto", // Replace 'Your-Modern-Font' with the desired modern font
                }}
              >
                {" "}
                {x.value}
                {info}
              </h1>
            );
          })}
          <Button
            style={customButtonStyles}
            onClick={() => setShowBreakdown(false)}
          >
            {" "}
            Hide{" "}
          </Button>
        </>
      ) : (
        <Button
          style={customButtonStyles}
          onClick={() => setShowBreakdown(true)}
        >
          Breakdown
        </Button>
      )}
    </>
  );
};
