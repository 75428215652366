import React from "react";
import "../App.css";
import { MobileLandingPage } from "./landingPages/mobileLanding/mobileLanding";
import { WebLandingPage } from "./landingPages/webLanding/webLanding";

export const LoggedOutHome: React.FC = () => {
  const isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  if (isMobile) {
    return <MobileLandingPage />;
  } else {
    return <WebLandingPage />;
  }
};
