import { Spinner } from "react-bootstrap";

export const InternalLoading = () => (
  <div
    style={{
      margin: "0 0 20px",
    }}
  >
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);
