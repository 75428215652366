import { DateTime } from "luxon";
import { UpcomingCostEventsWire } from "../wire/events";

type EventType =
  | "Tax"
  | "MOT"
  | "OilChange"
  | "Parking"
  | "Insurance"
  | "TyreChange";

export type UpcomingCostEvent = {
  vehicleReg: string;
  type: EventType;
  dueDate: DateTime;
  baseCost: number;
  additionalCost?: number;
};

export type UpcomingCostEvents = Array<UpcomingCostEvent>;

export const fromWireArr = (x: UpcomingCostEventsWire): UpcomingCostEvents =>
  x
    .map((y) => ({ ...y, dueDate: DateTime.fromISO(y.dueDate) }))
    .filter((x) => x.dueDate < DateTime.now().plus({ year: 1 }));
