import Form from "react-bootstrap/Form";
type VehicleRegTextInputT = {
  placeholder: string;
  value: string;
  callAPI: () => void;
  disabled: boolean;
  setValue: (x: string) => void;
};

export const VehicleRegInput = ({
  placeholder,
  value,
  callAPI,
  disabled,
  setValue,
}: VehicleRegTextInputT): JSX.Element => {
  const handleSubmit = (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // Your custom logic here, if needed
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      callAPI();
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Control
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown} // Add this line
          value={value}
          type="text"
          style={{
            display: "inline-block", // Change to inline-block
            margin: "0 10px", // Add margin for spacing
            position: "relative",
            flexFlow: "column",
            width: "100%",
            height: "100%",
            marginTop: "1rem",
            boxSizing: "border-box",
            minHeight: "60px",
            zIndex: 1,
            border: "none",
            padding: "0.375rem 0.75rem",
            transition:
              "border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
            outline: "none",
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: "20px",
            lineHeight: "normal",
            letterSpacing: "normal",
            textTransform: "none",
            fontStyle: "normal",
            color: "rgb(20, 24, 35)",
            backgroundColor: "rgba(237,240,245,255)",
            boxShadow: "rgb(0, 0, 0) 0px 0px 0px",
            borderRadius: "10px",
          }}
        />
      </Form>
    </>
  );
};
