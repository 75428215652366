import { DateTime } from "luxon";
import React from "react";
import {
  Bar,
  BarChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  barChartColours,
  colourPalette,
} from "../../components/shared/colourPalette";
import { ExpenseData } from "../../components/shared/costForecasts";
import { InternalLoading } from "../../components/shared/graphsAndIcons/spinner";

interface Props {
  data: ExpenseData[];
}

const BarChartComponent: React.FC<Props> = ({ data }) => {
  const currentDate = DateTime.local();
  const nextFiveMonths = [];
  for (let i = 0; i < 5; i++) {
    const nextMonth = currentDate.plus({ months: i });
    nextFiveMonths.push(nextMonth.toFormat("LLL"));
  }

  const currentMonthAbbreviated = currentDate.toFormat("LLL");

  const totalCost = data.reduce((acc, item) => acc + item.amount, 0);
  const averageCost = totalCost / data.length;
  const maximumAmount = Math.max(...data.map((item) => item.amount), 100);
  const roundedMaxAmount = Math.ceil(maximumAmount / 100) * 100;

  const referenceLines = [];
  for (let i = 0; i <= maximumAmount; i += maximumAmount / 3) {
    referenceLines.push(
      <ReferenceLine
        key={i}
        y={i}
        stroke={barChartColours.horizontalLines}
        strokeWidth={2}
      />,
    );
  }
  const amount = data[0].amount;
  const [integerPart, decimalPart] = amount.toFixed(2).split(".");

  const CustomizedGroupTick = ({ x, y, payload }: any) => (
    <g>
      <text
        x={x - 15}
        y={y + 10}
        style={{
          fontWeight: "bold",
          fill:
            payload.value == currentMonthAbbreviated
              ? barChartColours.accentLines
              : barChartColours.mediumText,
        }}
      >
        {payload.value}
      </text>
    </g>
  );
  const styledData = data.map((x) =>
    x.month == currentMonthAbbreviated
      ? { ...x, fill: barChartColours.accentLines }
      : x,
  );

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{ color: barChartColours.lightText, fontWeight: "bold" }}
          className="custom-tooltip"
        >
          <p className="label">{`${label} : £${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <div
        style={{
          padding: "1rem",
          borderRadius: "1rem",
          boxShadow: "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1)",
          fontFamily: "Roboto",
          overflow: "hidden",
          flex: "1",
          zIndex: 2,
          backgroundColor: colourPalette.primary,
          color: colourPalette.mediumGrey,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: 450,
              fontSize: "1.5rem",
              color: colourPalette.lightGreyBackGround,
            }}
          >
            {"This month"}
          </div>

          <div
            style={{
              fontSize: "2.5rem",
              fontWeight: 450,
              color: barChartColours.lightText,
            }}
          >
            <span style={{ fontSize: "1.5rem" }}>£</span>
            {integerPart}
            {decimalPart && (
              <span style={{ fontSize: "1.5rem" }}>.{decimalPart}</span>
            )}
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "normal",
                textAlign: "right",
                color: barChartColours.mediumText,
              }}
            >
              *Estimated Cost
            </div>
          </div>
        </div>
        <div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={styledData}
              margin={{ top: 20, right: 0, left: 10, bottom: 15 }}
              barSize={30}
            >
              <XAxis
                dataKey="month"
                tick={CustomizedGroupTick}
                tickFormatter={(tick) => {
                  return tick == currentMonthAbbreviated ? "" : tick;
                }}
              >
                <Label
                  value="Month"
                  position="bottom"
                  offset={0}
                  style={{
                    fontWeight: "bold",
                    fill: barChartColours.lightText,
                  }}
                />
              </XAxis>

              <YAxis
                yAxisId={0}
                orientation="right"
                domain={[0, roundedMaxAmount]}
                ticks={[maximumAmount]}
                axisLine={{ stroke: "none" }}
                tickFormatter={(tick) => {
                  return `£${parseInt(tick)}`;
                }}
                tick={{ fill: barChartColours.mediumText }}
              />
              <Tooltip cursor={{ fill: "none" }} content={<CustomTooltip />} />

              {referenceLines}
              <Bar
                dataKey="amount"
                fill={barChartColours.bars}
                radius={[5, 5, 0, 0]}
              />

              <ReferenceLine
                key={referenceLines.length + 1}
                y={averageCost}
                stroke={barChartColours.accentLines}
                strokeWidth={2}
                strokeDasharray="5 5"
              >
                <Label
                  value={`£${averageCost.toFixed(0)}`}
                  position="right"
                  fill={barChartColours.accentLines}
                  style={{ fontWeight: "bold" }}
                />
                <Label
                  value="AVG"
                  position="right"
                  fill={barChartColours.accentLines}
                  dy={16}
                />
              </ReferenceLine>

              <ReferenceLine
                key={referenceLines.length + 3}
                y={0}
                stroke={colourPalette.mediumGrey}
                strokeWidth={2}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export const BarChartCard = ({
  montlyCosts,
}: {
  montlyCosts: ExpenseData[];
}) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          position: "relative",
          height: "20rem",
          marginBottom: "7rem",
        }}
      >
        {" "}
        {montlyCosts.length == 0 ? (
          <InternalLoading />
        ) : (
          <BarChartComponent data={montlyCosts} />
        )}
      </div>
    </>
  );
};
