import { useAuth, useUser } from "@clerk/clerk-react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { DeleteCarsTE } from "../../apis/userCars/deleteCars";
import { AddVehileModal } from "../../components/Modals/addVehicle";
import { CardWithDelete } from "../../components/cards/cardWithDelete";
import {
  centeredContent,
  containerStyles,
  customButtonStyles,
} from "../../components/styles/generalStyles";
import { Vehicle } from "../../types/frontend/vehicles";
import { capitalizeFirstWord } from "../../components/shared/standardisations";

export const CarList = ({
  vehicles,
  handleSelect,
}: {
  vehicles: Array<Vehicle>;
  handleSelect: (x: string) => void;
}) => {
  const { user } = useUser();
  const { getToken } = useAuth();

  const handleRefresh = () => {
    window.location.reload();
  };

  const deleteCar = (registrationNumber: string) => {
    DeleteCarsTE({
      uuid: user?.id ?? "",

      getToken,
      vehicleRegistrations: [{ registrationNumber }],
    })().then(handleRefresh);
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <div style={{ ...containerStyles, width: "60vh" }}>
      <div style={{ ...centeredContent, minHeight: "100vh" }}>
        <div style={centeredContent}>
          <AddVehileModal showModal={showModal} setShowModal={setShowModal} />

          <div
            style={{
              width: "100%",
              minHeight: "40vh",
              paddingBottom: "7rem",
            }}
          >
            {vehicles.map((x, index) => {
              const valueText = `£${x.calculatedVehicleCosts.estimatedCurrentValue.toFixed(
                2,
              )}`;
              return (
                <CardWithDelete
                  key={index}
                  title={x.registrationNumber}
                  value={valueText}
                  text={`${capitalizeFirstWord(x.vehicleDetails.colour??"")}, ${capitalizeFirstWord(x.make)} ${capitalizeFirstWord(x.model)}`}
                  valueSubTitle={"Estimated Value"}
                  onDelete={() => deleteCar(x.registrationNumber)}
                  onClick={() => handleSelect(x.registrationNumber)}
                  deleteEnabled={true}
                  moreInfo={true}
                />
              );
            })}
            <Button
              style={{ ...customButtonStyles, padding: "none", margin: "none" }}
              onClick={() => setShowModal(true)}
            >
              <Plus
                style={{
                  width: "100%",
                  height: "3rem",
                }}
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
