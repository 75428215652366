import React, { useState, useEffect, CSSProperties } from "react";

export const ApologyBanner: React.FC = () => {
  const [visible, setVisible] = useState(true);

  const closePopup = () => {
    setVisible(false);
  };

  const styles: { [key: string]: CSSProperties } = {
    popupBanner: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "white",
      padding: "1rem",
      zIndex: 1000,
    },
    popupContent: {
      position: "relative",
      margin: "auto",
      paddingTop: "1rem",
      paddingRight: ".5rem",
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      top: "-1rem",
      right: "-.5rem",
      color: "white",
      fontSize: "30px",
      fontWeight: "bold",
      cursor: "pointer",
    },
  };

  return visible ? (
    <div style={styles.popupBanner}>
      <div style={styles.popupContent}>
        <span style={styles.closeButton} onClick={closePopup}>
          &times;
        </span>
        <p>
          We apologize for the look of the page. We are startup trying to find
          our first users and cannot afford a UI designer yet :( . <br />
          Thank you for your understanding.
        </p>
      </div>
    </div>
  ) : null;
};
