import { useAuth, useUser } from "@clerk/clerk-react";
import * as E from "fp-ts/Either";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { fetchUpcomingCostEventsTE } from "../../apis/userCars/events/fetchEvents";
import { SelectButton } from "../../components/buttons/vehicleSelectedButton";
import { CardWithDelete } from "../../components/cards/cardWithDelete";
import { colourPalette } from "../../components/shared/colourPalette";
import {
  ExpenseData,
  calculateMonthlyCosts,
} from "../../components/shared/costForecasts";
import { InternalLoading } from "../../components/shared/graphsAndIcons/spinner";
import { capitalizeFirstWord } from "../../components/shared/standardisations";
import { centeredContent } from "../../components/styles/generalStyles";
import { CostsArr } from "../../types/frontend/costs";
import {
  UpcomingCostEvent,
  UpcomingCostEvents,
} from "../../types/frontend/events";
import { Vehicle } from "../../types/frontend/vehicles";
import { BarChartCard } from "./costBarChart";

const getVehicleDetails = (
  vehicles: Vehicle[],
  vehicleReg: string
): { make: string; model: string } | null => {
  const vehicle = vehicles.find(
    (vehicle) => vehicle.registrationNumber === vehicleReg
  );
  return vehicle ? { make: vehicle.make, model: vehicle.model } : null;
};

const getEventDetails = (
  vehicles: Vehicle[],
  event: UpcomingCostEvent
): string => {
  const today = DateTime.now();
  const daysUntilDue = parseInt(
    event.dueDate.diff(today, "days").days.toString()
  );
  const historic = daysUntilDue < 0;
  const daysUntilDueAbs = Math.abs(daysUntilDue);
  const vehicleDetails = getVehicleDetails(vehicles, event.vehicleReg);
  const makeModelString = vehicleDetails
    ? `Your ${capitalizeFirstWord(vehicleDetails.make)} ${capitalizeFirstWord(
        vehicleDetails.model
      )}`
    : "Unknown Vehicle";

  switch (event.type) {
    case "Tax":
      return historic
        ? `${makeModelString} Tax was due ${daysUntilDueAbs} days ago.`
        : `${makeModelString} Tax is due in ${daysUntilDueAbs} days.`;
    case "MOT":
      return historic
        ? `${makeModelString} MOT was due ${daysUntilDueAbs} days ago.`
        : `${makeModelString} MOT is due in ${daysUntilDueAbs} days.`;
    case "OilChange":
      return historic
        ? `${makeModelString} required an oil change ${daysUntilDueAbs} days ago.`
        : `${makeModelString} needs an oil change in ${daysUntilDueAbs} days.`;
    case "Parking":
      return historic
        ? `${makeModelString} parking permit was due ${daysUntilDueAbs} days ago.`
        : `${makeModelString} parking permit due in ${daysUntilDueAbs} days.`;
    case "Insurance":
      return historic
        ? `${makeModelString} insurance expired ${daysUntilDueAbs} days ago.`
        : `${makeModelString} insurance renewal in ${daysUntilDueAbs} days.`;
    case "TyreChange":
      return historic
        ? `${makeModelString} required a tyre change approximately ${daysUntilDueAbs} days ago.`
        : `${makeModelString} will require a tyre change in ${daysUntilDueAbs} days.`;
    default:
      return `Unknown event type for ${makeModelString}. This is unexpected. The developer who wrote this apologises for the error.`;
  }
};
const EventsList = ({
  upcomingCostEvents,
  vehicles,
  navigateToVehicleMOTPage,
}: {
  upcomingCostEvents: UpcomingCostEvents;
  vehicles: Array<Vehicle>;
  navigateToVehicleMOTPage: (registrationNumber: string) => void;
}) => {
  return (
    <>
      <div style={{ ...centeredContent, paddingBottom: "5rem" }}>
        {upcomingCostEvents.map((x, index) => {
          const cost: string =
            x.additionalCost && x.additionalCost > 5
              ? `£${x.baseCost} + £${x.additionalCost.toFixed(0)}`
              : `£${x.baseCost}`;

          const subtitleText = getEventDetails(vehicles, x);
          if (x.type == "MOT") {
            return (
              <CardWithDelete
                key={index}
                title={x.type}
                value={cost}
                onClick={() => navigateToVehicleMOTPage(x.vehicleReg)}
                text={subtitleText}
                valueSubTitle={x.dueDate.toFormat("dd LLL yyyy")}
                deleteEnabled={false}
                moreInfo={true}
              />
            );
          } else {
            return (
              <CardWithDelete
                key={index}
                title={x.type}
                value={cost}
                text={subtitleText}
                valueSubTitle={x.dueDate.toFormat("dd LLL yyyy")}
                deleteEnabled={false}
              />
            );
          }
        })}
      </div>
    </>
  );
};

export const UpcomingCosts = ({
  vehicles,
  navigateToVehicleMOTPage,
}: {
  vehicles: Array<Vehicle>;
  navigateToVehicleMOTPage: (registrationNumber: string) => void;
}) => {
  const [selectedVehicles, setSelectedVehicle] = useState<Array<string>>(
    vehicles.map((x) => x.registrationNumber)
  );

  const costArr: CostsArr = [
    "Parking",
    "MOT",
    "Tax",
    "Fuel",
    "ULEZ",
    "Congestion Charge",
    "Depreciation",
    "Insurance",
    "Service",
  ];
  const selectableCostArr: CostsArr = [
    "Parking",
    "MOT",
    "Tax",
    "Fuel",
    "ULEZ",
    "Congestion Charge",
    "Depreciation",
  ];

  const [selectedCosts, setSelectedCosts] =
    useState<CostsArr>(selectableCostArr);

  const [apiloading, setApiloading] = useState<boolean>(false);

  const [upcomingCostEvents, setUpcomingCostEvents] =
    useState<UpcomingCostEvents>([]);

  const { user } = useUser();
  const { getToken } = useAuth();

  useEffect(() => {
    setApiloading(true);
    // Check if vehicles is initially empty
    fetchUpcomingCostEventsTE({ uuid: user?.id ?? "", getToken })().then(
      E.fold(
        (x) => {
          setApiloading(false);
        }, // TODO return the actual error here,
        (data) => {
          setApiloading(false);
          setUpcomingCostEvents(data);
        }
      )
    );
  }, []);

  const [montlyCosts, setMonthlyCosts] = useState<Array<ExpenseData>>([]);
  useEffect(() => {
    if (vehicles[0]) {
      setMonthlyCosts(
        calculateMonthlyCosts({
          vehicles: vehicles.filter((x) =>
            selectedVehicles.includes(x.registrationNumber)
          ),
          upcomingCostEvents: upcomingCostEvents.filter((x) =>
            selectedCosts.includes(x.type as any)
          ),

          selectedCosts,
        })
      );
    }
  }, [upcomingCostEvents, selectedVehicles, selectedCosts]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        marginTop: "4rem",
      }}
    >
      <div
        style={{
          ...centeredContent,
          minHeight: "100vh",
          width: "100%",
          height: "90vh",
        }}
      >
        <SelectButton
          inputArr={vehicles.map((x) => x.registrationNumber)}
          setSelectedValues={setSelectedVehicle}
          selectedValues={selectedVehicles}
          width="30%"
          height="3rem"
        />
        {selectedVehicles.length === 0 ? (
          <h3
            style={{
              margin: "2rem",
              fontSize: "1rem",
              color: colourPalette.reject,
              fontFamily: "Roboto",
            }}
          >
            Please select a Vehicle
          </h3>
        ) : (
          <>
            <BarChartCard montlyCosts={montlyCosts} />
            <SelectButton
              inputArr={costArr}
              setSelectedValues={setSelectedCosts}
              selectedValues={selectedCosts}
              width="20%"
              height="3rem"
            />
          </>
        )}
        {apiloading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InternalLoading />
          </div>
        ) : (
          <>
            <EventsList
              vehicles={vehicles}
              upcomingCostEvents={
                upcomingCostEvents.filter((x) =>
                  selectedVehicles.includes(x.vehicleReg)
                ) ?? []
              }
              navigateToVehicleMOTPage={navigateToVehicleMOTPage}
            />
          </>
        )}
      </div>
    </div>
  );
};
