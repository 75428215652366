import * as TE from "fp-ts/TaskEither";
import { pipe } from "fp-ts/lib/function";
import { urlBasePath } from "../../components/shared/graphsAndIcons/env/vars";

export const AddCarTE = ({
  uuid,
  getToken,
  registrationNumber,
}: {
  uuid: string;
  getToken: any;
  registrationNumber: string;
}) => {
  const url = `${urlBasePath}/user/${uuid}/cars`;
  return pipe(
    TE.tryCatch(
      async () => {
        return fetch(url, {
          headers: {
            Authorization: `${await getToken({ template: "userId_JWT" })}`,
          },
          method: "post",
          body: JSON.stringify({
            registrationNumber,
          }),
        });
      },
      (err): any => {
        console.warn("error sending the request:", JSON.stringify(err));
        return "FailedToSend";
      },
    ),
    TE.chain((rsp) => {
      if (rsp.status == 200) {
        return TE.right(undefined);
      }
      return TE.left("UnknownError");
    }),
  );
};
